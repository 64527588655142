import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiRequestService } from "../../../services/api-request.service";
import { User } from '../../../utils/User';
import { Config } from "../../../utils/Config";
import { NotificationsService, NotificationType } from 'angular2-notifications';

import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith, debounceTime, switchMap} from 'rxjs/operators';


export interface Instructor {
	id: number;
	name: string;
}


@Component({
  selector: 'app-usuarios-instrutores',
  templateUrl: './usuarios-instrutores.component.html',
  styleUrls: ['./usuarios-instrutores.component.scss']
})
export class UsuariosInstrutoresComponent implements OnInit {

	formCadastro:FormGroup = new FormGroup({
		instructor: new FormControl('', Validators.required)
	});

	dados:any;
	config = Config;
	usuario:User = new User();

	selectedInstructor:Instructor = undefined;
	filteredInstructors: Observable<Instructor[]>;

	instructors:Instructor[] = [];

	constructor(
		private route: ActivatedRoute,
		private _notifications: NotificationsService,
		public api: ApiRequestService
	) {
		console.log(this.usuario);

		this.filteredInstructors = this.formCadastro.get('instructor').valueChanges
		.pipe(
			startWith(''),
			map(instructor => {
				this.GetInstructors();
				return this.instructors;
			})
		);

	}

	GetInstructors(){

		let exclude:number[] = [this.dados.id];
		for(let i:number=0; i<this.dados.instructors.length; i++){
			exclude.push( this.dados.instructors[i].pivot.instructor_id );
		}

		this.api.Get("user/instructors?name="+this.formCadastro.get('instructor').value+"&exclude="+exclude.join(",")).subscribe((res:any)=>{
			this.instructors = res.data;
		})
	}

	GetField(field:string, form:FormGroup = this.formCadastro){
		return form.get(field);
	}

	ngOnInit() {

		this.route.params.subscribe(params => {
			this.CarregarDados( params['id'] );
		});

	}

	CarregarDados(id){

		this.api.Get("user/"+id).subscribe((ret:any)=>{
			console.log(ret);
			this.dados = ret;
		});

	}

	InstructorSel(opt:Instructor){
		console.log(opt);

		this.formCadastro.setValue({
			instructor: ""
		})

		this.api.Post("user/"+this.dados.id+"/instructor", {
			instructor_id: opt.id
		}).subscribe((ret:any)=>{

			this.CarregarDados(this.dados.id);
			
			this._notifications.create("Sucesso!", "Professor adicionado com sucesso", NotificationType.Success, { timeOut: 2500 } );

		}, (error)=>{
			console.log(error);
			this._notifications.create("Erro!", error.error.error, NotificationType.Error, { timeOut: 5000 } );
		});

	}

	RemoveInstructor(instrutor){

		instrutor.excluindo = true;

		this.api.Delete("user/"+this.dados.id+"/instructor?instructor_id="+instrutor.id).subscribe((ret:any)=>{

			this.CarregarDados(this.dados.id);
			
			this._notifications.create("Sucesso!", "Professor removido com sucesso", NotificationType.Success, { timeOut: 2500 } );

		}, (error)=>{
			this._notifications.create("Erro!", "Erro ao remover professor", NotificationType.Error, { timeOut: 5000 } );
		});

	}

}
