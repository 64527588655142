import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ApiRequestService } from '../../../services/api-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { CleanTextForURLPipe } from '../../../pipes/clean-text-for-url.pipe';
import { Validation } from '../../../utils/Validation';
import { Config } from "../../../utils/Config";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatMenuTrigger} from '@angular/material';
import { SimulatorFeedbacksAddVideoComponent } from "../simulator-feedbacks-add-video/simulator-feedbacks-add-video.component";


@Component({
  selector: 'app-simulator-feedbacks-form',
  templateUrl: './simulator-feedbacks-form.component.html',
  styleUrls: ['./simulator-feedbacks-form.component.scss']
})
export class SimulatorFeedbacksFormComponent extends Validation implements OnInit {

	formCadastro:FormGroup = new FormGroup({
		title: new FormControl('', Validators.required),
		description: new FormControl('', Validators.required)
	});
	enviandoForm = false;

	videos:any[] = [];

	queryParams:any;
	dados:any = {};
	config:any = Config;

	constructor(
		public dialog: MatDialog,
		private route: ActivatedRoute,
		private router: Router,
		private notifications: NotificationsService,
		private api:ApiRequestService,
		private cleanText:CleanTextForURLPipe
	) {
		super();
	}

	ngOnInit() {

		this.route.params.subscribe(ret => {
			if(ret.id!=undefined){
				this.queryParams = ret;
				this.dados.id = ret.id;
				this.CarregarDados(ret.id);
			}
		});
	}


	CarregarDados(id:any){

		this.api.Get("simulator/feedback/"+id).subscribe((response:any)=>{

			this.formCadastro.get("title").setValue(response.title);
			this.formCadastro.get("description").setValue(response.description);

			this.videos = JSON.parse(response.videos);
			console.log(this.dados);
		}, error=>{
			console.error("ERRO", error)
		});

	}


	AdicionarVideo(): void {
		const dialogRef = this.dialog.open(SimulatorFeedbacksAddVideoComponent, {
			width: '600px'
		});

		dialogRef.afterClosed().subscribe(res => {
			if(res!=undefined){
				console.log(res);
				this.videos.push(res);
			}
		});
	}

	RemoverVideo(i:number){
		this.videos.splice(i, 1);
	}

	Cadastrar(){

		if(!this.formCadastro.valid){
			return;
		}

		let data = JSON.parse(JSON.stringify(this.formCadastro.value));

		data.videos = JSON.stringify(this.videos);
		
		console.log(data);

		this.enviandoForm = true;

		
		let url = "simulator/feedback";
		if(this.dados.id){
			url = url+"/"+this.dados.id;
		}
		
		this.api.Post(url, data).subscribe((response:any)=>{
			this.notifications.create("Sucesso!", "Caso salvo com sucesso!", NotificationType.Success, { timeOut: 5000 } );
			this.router.navigate(['/simulador/feedbacks'])
		}, (error:any)=>{
			this.enviandoForm = false;

			this.notifications.create("Erro!", "Erro ao cadastrar. Tente novamente mais tarde.", NotificationType.Error, { timeOut: 5000 } );
			console.error(error.error);

		});
		
	}

}
