import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ApiRequestService } from '../../../services/api-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Validation } from '../../../utils/Validation';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';


@Component({
	selector: 'app-simulator-casos-fluxograma-etapa-modal',
	templateUrl: './simulator-casos-fluxograma-etapa-modal.component.html',
	styleUrls: ['./simulator-casos-fluxograma-etapa-modal.component.scss']
})
export class SimulatorCasosFluxogramaEtapaModalComponent extends Validation implements OnInit {

	formCadastro:FormGroup= new FormGroup({
		description: new FormControl('', Validators.required),
		tips: new FormControl('')
	});
	enviandoForm = false;

	parametros:any[] = undefined;
	acoes:any[] = undefined;
	feedbacks:any[] = undefined;

	operator:any = {
		tips: '',
		parameters: [],
		outputs: [
			{
				name: "Correta",
				type: "correct",
				timer: 0,
				actions: [],
				feedbacks: []
			},
			{
				name: "Errada",
				type: "wrong",
				timer: 0,
				actions: [],
				feedbacks: []
			}
		]
	}

	linksTypes:any = {
		correct: "Correta",
		partially: "Parcialmente correta",
		wrong: "Errada",
		random: "Aleatória",
		dialog: "Caixa de Diálogo",
	};

	medicineOpts:any = {
		units: [
			{ key: "mg", name: "mg" },
			{ key: "mcg", name: "mcg" },
			{ key: "mcg/min", name: "mcg/min" },
			{ key: "mcg/kg/min", name: "mcg/kg/min" },
		],
		method: [
			{ key: "bolus", name: "Em bolus" },
			//{ key: "flush", name: "Flush e elevação de membro" },
			{ key: "equipo", name: "Correr em gotas por minuto" },
			{ key: "infusion_pump", name: "Bomba de infusão" }
		],
		equipoIntervals: [
			{ key: 10, name: "10 minutos" },
			{ key: 20, name: "20 minutos" },
			{ key: 0, name: "Livre" }
		]
	}

	constructor(
		public dialogRef: MatDialogRef<SimulatorCasosFluxogramaEtapaModalComponent>,
    	@Inject(MAT_DIALOG_DATA) public dataDialog:any,
		private route: ActivatedRoute,
		private router: Router,
		private notifications: NotificationsService,
		private api:ApiRequestService
	) {
		super();
	}

	ngOnInit() {
		this.CarregarParametros();
		this.CarregarAcoes();
		this.CarregarFeedbacks();

		if(this.dataDialog.id_etapa){
			this.CarregarDados();
		}
	}

	dropActions(event: CdkDragDrop<string[]>, output:any) {
		moveItemInArray(output.actions, event.previousIndex, event.currentIndex);
	}

	CarregarDados(){
		this.api.Get("simulator/"+this.dataDialog.id_caso+"/flow/operator/"+this.dataDialog.id_etapa).subscribe((res:any)=>{
			console.log(res);
			this.formCadastro.get("description").setValue(res.description);
			this.formCadastro.get("tips").setValue(res.tips);
			this.operator.parameters = res.parameters;
			this.operator.outputs = res.outputs;

			//
			this.operator.outputs.forEach((outs)=>{
				outs.actions.forEach((act)=>{
					if(!act.demonstration) act.demonstration = {};
				})
			})

			/*for(let i:number = 0; i<this.operator.outputs.length; i++){
				if(!this.operator.outputs[i].demonstration) this.operator.outputs[i].demonstration = {};
			}*/

		}, error=>{
			console.error("ERRO", error)
		});
	}

	CarregarParametros(){

		this.api.Get("simulator/parameters").subscribe((response:any[])=>{
			this.parametros = response;
		}, error=>{
			console.error("ERRO", error)
		});

	}

	CarregarAcoes(){

		this.api.Get("simulator/actions").subscribe((response:any[])=>{
			this.acoes = response;
		}, error=>{
			console.error("ERRO", error)
		});

	}

	CarregarFeedbacks(){

		this.api.Get("simulator/feedback").subscribe((response:any)=>{
			this.feedbacks = response.data;
		}, error=>{
			console.error("ERRO", error)
		});

	}

	RetornarParametro(id:number):any {

		for(let i:number=0; i<this.parametros.length; i++){
			if(this.parametros[i].id==id){
				return this.parametros[i];
			}
		}

		return undefined;

	}

	RetornarAcao(id:number):any {

		for(let i:number=0; i<this.acoes.length; i++){
			if(this.acoes[i].id==id){
				return this.acoes[i];
			}
		}

		return undefined;

	}

	PacienteAddParam() {
		this.operator.parameters.push({
			id: undefined,
			value: [],
			valueMax: []
		});
	}

	PacienteRemoveParam(index) {
		this.operator.parameters.splice(index, 1);
	}



	AddOutput() {
		this.operator.outputs.push({
			actions: [],
			timer: 0
		});
	}

	RemoveOutput(index) {
		this.operator.outputs.splice(index, 1);
	}


	AddFeedback(output) {

		if(output.feedbacks==undefined) 
			output.feedbacks = [];

		output.feedbacks.push({});
	}

	RemoveFeedback(output, index) {

		output.feedbacks.splice(index, 1);
	}

	AddAcao(output) {
		output.actions.push({
			required: true,
			demonstration: {}
		});

		console.log(output);
	}

	RemoveAcao(output, index) {
		output.actions.splice(index, 1);
	}

	//UPLOAD DE ARQUIVOS
	onFileChangedS3(event, field) {
		let f:File = event.target.files[0];

		field.exam_result = "carregando..."

		this.api.UploadS3(f, 'public/simulator/', true).then((ret:any)=>{
			field.exam_result = ret.url;
		});
	}

	salvando = false;
	Salvar(){

		if(!this.salvando && this.formCadastro.valid){
			this.operator.description = this.formCadastro.get("description").value;
			this.operator.tips = this.formCadastro.get("tips").value;

			let data = Object.assign({}, this.operator);
			
			data.parameters = JSON.stringify(data.parameters);
			data.outputs = JSON.stringify(data.outputs);

			console.log(this.dataDialog);
			console.log(data);

			this.salvando = true;

			let url = "simulator/"+this.dataDialog.id_caso+"/flow/operator";

			if(this.dataDialog.id_etapa){
				url = url+"/"+this.dataDialog.id_etapa;
			}


			this.api.Post(url, data).subscribe((res:any)=>{
				this.salvando = false;
				console.log(res);
				this.dialogRef.close(res);
			}, (error:any)=>{
				this.salvando = false;
				console.error(error);
			})

		}
	}

	Fechar(){
		this.dialogRef.close();
	}

}
