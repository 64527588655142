import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith, debounceTime, switchMap} from 'rxjs/operators';
import { Config } from "../../../utils/Config";
import { User } from '../../../utils/User';

import { ApiRequestService } from '../../../services/api-request.service';
import { ValidateEmailExist } from '../../../utils/ValidateEmailExist';

import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Component({
  selector: 'app-users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.scss']
})
export class UsersFormComponent implements OnInit {

	usuario:User = new User();

	cadastro:any = {
		info: {}
	};

	estados:any[] = [];
	cidades:any[] = [];
	validateEmailExist:ValidateEmailExist = new ValidateEmailExist(this.api);

	enviandoForm:boolean = false;
	editando:boolean = false;

	formCadastro:FormGroup= new FormGroup({
		name: new FormControl('', Validators.required),
		email: new FormControl('', [Validators.required, Validators.email], this.validateEmailExist.CheckEmailTaken.bind(this.validateEmailExist)),
		password: new FormControl('', [Validators.required, Validators.minLength(6)]),
		password2: new FormControl('', [Validators.required, Validators.minLength(6), this.CheckPassword]),
		phone: new FormControl('', Validators.required),
		gender: new FormControl('', Validators.required),
    birthdate: new FormControl(''),
    cpf: new FormControl(''),
    address: new FormControl(''),
    street_number: new FormControl(''),
    district: new FormControl(''),
    zip_code: new FormControl(''),
		state: new FormControl('', Validators.required),
		city: new FormControl('', Validators.required),
		instructor: new FormControl(false),
	});

	config:any = Config;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private notifications: NotificationsService,
		private api:ApiRequestService
	) {
	}

	CheckPassword(control: AbstractControl): { [key: string]: boolean } | null {

	    if (control.parent!=undefined && control.parent.get('password').value!=control.parent.get('password2').value) {
	        return { 'pwdMatch': true };
	    }
	    return null;
	}

	ngOnInit() {

		this.route.params.subscribe(ret => {
			if(ret.id!=undefined){
				this.CarregarDados(ret.id);
			}
		});

		this.CarregarEstados();
	}

	dados;
	CarregarDados(id){

		this.editando = true;

		this.formCadastro = new FormGroup({
			name: new FormControl('', Validators.required),
			email: new FormControl('', [Validators.required, Validators.email]),
			phone: new FormControl('', Validators.required),
			gender: new FormControl('', Validators.required),
      birthdate: new FormControl(''),
      cpf: new FormControl(''),
      address: new FormControl(''),
      street_number: new FormControl(''),
      district: new FormControl(''),
      zip_code: new FormControl(''),
			state: new FormControl('', Validators.required),
			city: new FormControl('', Validators.required),

			password: new FormControl('', [Validators.minLength(6)]),
			password2: new FormControl('', [Validators.minLength(6), this.CheckPassword])
		});

		if(id=="me") id = this.usuario.data.user.id;

		this.api.Get("../user/"+id).subscribe((res:any)=>{
			this.dados = res;
			//console.log(this.dados);

			if(res.info){

				let dataFormatada;
				if(res.info.birthdate){
					let data = res.info.birthdate.split("-");
					dataFormatada = data[2]+"/"+data[1]+"/"+data[0];
				} else {
					dataFormatada = "";
				}

				this.formCadastro.setValue({
					name: res.name,
					email: res.email,
					phone: res.info.phone,
					gender: res.info.gender,
          birthdate: dataFormatada,
          cpf: res.info.cpf,
          address: res.info.address,
          street_number: res.info.street_number,
          district: res.info.district,
          zip_code: res.info.zip_code,
					state: res.info.state,
					city: res.info.city.toString(),
					password: "",
					password2: ""
				});
			} else {
				this.formCadastro.get("name").setValue(res.name);
				this.formCadastro.get("email").setValue(res.email);
			}

			this.CarregarCidades();

		}, error=> {
			alert("Erro ao carregar dados. Contate o administrador");
			console.log(error.error);
		})
	}

	GetField(field:string, form:FormGroup = this.formCadastro){
		return form.get(field);
	}

	GetError(field:string, form:FormGroup = this.formCadastro){
		if(form.get(field).hasError('required')){
			return "Este campo é obrigatório";
		} else if(form.get(field).hasError('email')){
			return "E-mail inválido";
		} else if(form.get(field).hasError('minlength')){
			return "A senha precisa ter no mínimo 6 caracteres";
		} else if(form.get(field).hasError('pwdMatch')){
			return "As senhas não coincidem";
		} else if(form.get(field).hasError('emailTaken')){
			return "Este e-mail já está cadastrado no SimSave. ";
		} else {
			return "Preencha este campo corretamente";
		}
	}


	CarregarEstados(){

		this.api.Get("../estados").subscribe((response:any[])=>{
			this.estados = response;
		}, error=>{
			console.error("ERRO", error)
		});

	}

	CarregarCidades(){

		this.cidades = [];

		this.api.Get("../cidades/"+this.formCadastro.get('state').value).subscribe((response:any[])=>{
			this.cidades = response;
		}, error=>{
			console.error("ERRO", error)
		});

	}

	Cadastrar(){

		if(this.formCadastro.valid){
			let v = this.formCadastro.value;

			let data = {
				name: v.name,
				email: v.email,
				password: v.password,
				info: {
					phone: v.phone,
					gender: v.gender,
					city: v.city,
					state: v.state,
          birthdate: (v.birthdate == "") ? null : v.birthdate.substr(4, 4) + "-" + v.birthdate.substr(2, 2) + "-" + v.birthdate.substr(0, 2),
          cpf: v.cpf,
          address: v.address,
          street_number: v.street_number,
          district: v.district,
          zip_code: v.zip_code,
				}
      }

			let url = !this.dados ? "../user" : "../user/"+this.dados.id;

			this.enviandoForm = true;
			this.api.Post(url, data).subscribe((user: any)=>{
        this.enviandoForm = false;
				console.log('usuario salvo', user)
        this.router.navigate(['users', user.id])
			}, (error:any)=>{
				this.enviandoForm = false;

				this.notifications.create("Erro!", "Erro ao cadastrar usuário. Tente novamente mais tarde.", NotificationType.Error, { timeOut: 5000 } );
				console.error(error.error);

			});
		}
	}


	SalvoSucesso(){
		this.enviandoForm = false;
		this.notifications.create("Sucesso!", "Usuário salvo com sucesso", NotificationType.Success, { timeOut: 2500 } );

		this.Voltar();

	}

	Voltar(): void {
		this.router.navigate(['usuarios']);
	}

}
