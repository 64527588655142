import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from '../../../services/api-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { JourneyStepSearchComponent } from "../../../dialogs/journey-step-search/journey-step-search.component";

@Component({
  selector: 'app-quiz-questions',
  templateUrl: './quiz-questions.component.html',
  styleUrls: ['./quiz-questions.component.scss']
})
export class QuizQuestionsComponent implements OnInit {

	quiz_id:number;
	quiz:any;

	constructor(
		public dialog: MatDialog,
		private route: ActivatedRoute,
		private api:ApiRequestService,
		private _notifications: NotificationsService
	) { }

	ngOnInit() {
		this.route.params.subscribe(ret => {
			this.quiz_id = ret.id;
			this.LoadQuiz();
		});
	}

	dropItem( event: CdkDragDrop<any[]>) {
		moveItemInArray(this.quiz.questions, event.previousIndex, event.currentIndex);
		this.SaveOrder();
	}

	LoadQuiz() {

		this.api.Get("quiz/"+this.quiz_id).subscribe((res:any)=>{
			this.quiz = res;
		})

	}

	AddQuestion(){

		const dialogRef = this.dialog.open(JourneyStepSearchComponent, {
			width: '600px',
			data: {
				types: 'questions'
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if(result) {
				this.quiz.questions.push({
					question_id: result.question_id,
					title: result.title
				});
				this.AddQuestionProcess();
			}
		});

	}

	AddQuestionProcess(){
		
		let questions = [];
		this.quiz.questions.forEach((q:any)=>{ questions.push(q.question_id) })

		this.api.Post("quiz/"+this.quiz.id, {questions: questions}).subscribe((res:any)=>{
			this._notifications.success("Sucesso!", "Questão adicionada com sucesso", { timeOut: 5000 });
			this.quiz = res;
		});
	}

	RemoveQuestion(data:any){

		if(!confirm("Tem certeza que deseja remover esta questão?")) return;

		this.api.Delete("quiz/"+this.quiz.id+"/question/"+data.question_id).subscribe((res:any)=>{
			this._notifications.success("Sucesso!", "Questão removida com sucesso", { timeOut: 5000 });
			this.LoadQuiz();
		})

	}

	SaveOrder(){
		let questions:any[] = [];

		let iQuestion = 0;

		this.quiz.questions.forEach(q=> {
			questions.push({
				id: q.id,
				order: iQuestion
			})

			iQuestion++;
		});

		this.api.Post("quiz/"+this.quiz.id+"/order", {questions: questions}).subscribe((res:any)=>{
			this._notifications.success("Sucesso!", "Ordem alterada com sucesso", { timeOut: 5000 });
		})
	}

}
