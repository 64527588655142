import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from "../../../services/api-request.service";
import { PageEvent } from '@angular/material/paginator';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ActivatedRoute, Router } from '@angular/router';

import { Config } from "../../../utils/Config";
import { User } from '../../../utils/User';

@Component({
  selector: 'app-simulator-feedbacks-listar',
  templateUrl: './simulator-feedbacks-listar.component.html',
  styleUrls: ['./simulator-feedbacks-listar.component.scss']
})
export class SimulatorFeedbacksListarComponent implements OnInit {


	dados:any = undefined;

	columnsToDisplay = ["title", "acoes"];
	queryParams:any;

	constructor(
		private route: ActivatedRoute,
		private _notifications: NotificationsService,
		public apiRequest: ApiRequestService
	) { }

	ngOnInit() {
		this.route.params.subscribe(ret => {
			this.queryParams = ret;
			this.CarregarDados();
		});
	}

	pagAtual:number = 0;
	CarregarDados(pag:number = 0, pageSize:number = 25){

		if(this.dados!=undefined)
			this.dados.data = [];

		let url = "simulator/feedback?page="+(pag+1)+"&pageSize="+pageSize+"&type="+this.queryParams.type;

		this.apiRequest.Get(url).subscribe((ret:any)=>{
			this.dados = ret;
		});

		this.pagAtual = pag;

	}

	Excluir(data){
		if( confirm("Tem certeza que deseja remover este item?") ){

			this.apiRequest.Delete("simulator/feedback/"+data.id).subscribe((ret:any)=>{
				this._notifications.create("Sucesso!", "Registro removido com sucesso", NotificationType.Success, { timeOut: 5000 } );
				this.CarregarDados(this.pagAtual);
			});

		}
	}

}
