import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'status-aula',
  templateUrl: './status-aula.component.html',
  styleUrls: ['./status-aula.component.scss']
})
export class StatusAulaComponent implements OnInit {

	@Input() aula:any;

	constructor() { }

	ngOnInit() {
	}

}
