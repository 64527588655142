import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ApiRequestService } from "../../../services/api-request.service";
import {
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";

@Component({
  selector: "app-users-add-manual-payment",
  templateUrl: "./users-add-manual-payment-boleto.component.html",
})
export class UsersAddManualPaymentBoletoComponent {
  user_id: any
  plan: any
  value = new FormControl("", [Validators.min(0), Validators.required]);

  paymentForm: FormGroup = new FormGroup({
    value: this.value
  });

  saving = false;

  constructor(
    private api: ApiRequestService,
    public dialogRef: MatDialogRef<UsersAddManualPaymentBoletoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.user_id = data.user.id
    this.plan = data.user.plans[0]
    this.value.setValue(this.plan.total_value)
  }

  addManualPaymentBoleto(): void {
    this.api.Post('paymentsBoleto', {
      user_plan_id: this.plan.id,
      amount: this.value.value
    }).subscribe((res: any) => {
      this.dialogRef.close(res);
    })
  }

  close(): void {
    this.dialogRef.close();
  }
}
