import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from "../../services/api-request.service";
import { PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { UserProductsComponent } from '../../dialogs/user-products/user-products.component';

import { Config } from "../../utils/Config";
import { User } from '../../utils/User';


@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

	painelAberto:boolean = false;
	usuario:User = new User();
	config = Config;
	products:any;

	tabela:any = [];
	dados:any;

	filtros:any = {
		query: "",
		role: "",
		product: "",
		coordenated: false
	};

	columnsToDisplay = ["name", "email", "roles", "subordinate", "products", "acoes"];

	constructor(
    	public dialog: MatDialog,
		private _notifications: NotificationsService,
		public apiRequest: ApiRequestService
	) { }

	ngOnInit() {
		this.CarregarDados();
		this.LoadProducts();
	}

	pagAtual:number = 0;
	CarregarDados(pag:number = 0, pageSize:number = 25){

		if(this.dados!=undefined)
			this.dados.data = [];

		let url = "user?page="+(pag+1)+"&pageSize="+pageSize;

		if(this.filtros.query!=""){
			url += "&q="+this.filtros.query;
		}

		if(this.filtros.role!=""){
			url += "&role="+this.filtros.role;
		}

		if(this.filtros.product!=""){
			url += "&product="+this.filtros.product;
		}

		if(this.filtros.coordenated!=""){
			url += "&coordenated";
		}

		this.apiRequest.Get(url).subscribe((ret:any)=>{
			//console.log(ret);
			this.dados = ret;
		});

		this.pagAtual = pag;

	}

	LoadProducts() {
		this.apiRequest.Get("products").subscribe(res=>this.products = res);
	}

	public AtualizarPaginacao(event?:PageEvent){
		this.CarregarDados(event.pageIndex, event.pageSize);
	}

	public AjustaPermissoes(roles){
		let ret:any[] = [];

		for(let i:number=0; i<roles.length; i++){
			for(let j:number=0; j<Config.roles.length; j++){
				if(roles[i].role==Config.roles[j].key){
					ret.push(Config.roles[j]);
				}
			}
		}

		return ret;
	}

	Filtrar(){
		this.pagAtual = 0;
		this.CarregarDados();
	}

	LimparFiltro() {

		this.filtros = {
			query: "",
			role: "",
			product: "",
			coordenated: false
		};

		this.Filtrar();
	}

	RoleContains(role:any,roles:any) {
		return roles.filter((item) => 	{ return item.role === role }).length;
	}

	AddProducts(user:any){
		const dialogRef = this.dialog.open(UserProductsComponent, {
			data: { id: user.id },
			width: '800px'
		});

		//dialogRef.afterClosed().subscribe(result => {});
	}

	AddDaysInPlan(user:any){

		let days = window.prompt("Quantos dias deseja adicionar no plano? (máximo de 15 dias)", "15");

		if(days){
			this.apiRequest.Post("user/"+user.id+"/addDaysInPlan", {days: days}).subscribe((res:any)=>{
				this._notifications.create("Sucesso!", "Prazo de expiração aumentado com sucesso!", NotificationType.Success, { timeOut: 2500 } );
			}, (error:any)=>{
				this._notifications.create("Erro!", error.error.error, NotificationType.Error, { timeOut: 5000 } );

			});
		}

	}

}
