import { Component, OnInit, ViewChild } from '@angular/core';

import { CalendarComponent } from 'ng-fullcalendar';
import { Options } from 'fullcalendar';
import { User } from '../../utils/User';
import { environment } from '../../../environments/environment';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { StudioBlockInfoModalComponent } from "../../pages/studio/studio-block-info-modal/studio-block-info-modal.component";
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/min';
import { EventEmitterService } from '../../services/event-emitter.service';

@Component({
  selector: 'calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']
})
export class CalendarioComponent implements OnInit {

	calendarOptions: Options;
	@ViewChild(CalendarComponent) ucCalendar: CalendarComponent;

	usuario:User = new User();

	constructor(
		public dialog: MatDialog
	) { }

	ngOnInit() {
		this.calendarOptions = {
			height: "auto",
			timeFormat: "H:mm",
			displayEventEnd: true,
			locale: "pt-br",
			header: {
				left: 'prev,next today',
				center: 'title',
				right: 'month,agendaWeek,agendaDay,listMonth'
			},
			buttonText: {
				today: "Hoje",
				month: "Mês",
				week: "Semana",
				day: "Dia",
				list: "Lista"
			},
			events: {
				url: environment.apiUrl+"studio/calendar",
				data: {
					token: this.usuario.data.token
				}
			}
		};

		EventEmitterService.get("calendar.update").subscribe(()=>{
			console.log("ATUALAR CAL");
			this.ucCalendar.fullCalendar('refetchEvents');
		})
	}

	eventClick(info:any):void {
		console.log(info);

		if(info!=null){
			const dialogRef = this.dialog.open(StudioBlockInfoModalComponent, {
				width: '600px',
				data: info.event
			});
		}
	}

	Update(){
		
	}

}
