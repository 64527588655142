import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ApiRequestService } from "../../../services/api-request.service";
import {
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";

@Component({
  selector: "app-users-add-old-plan",
  templateUrl: "./users-add-old-plan.component.html",
})
export class UsersAddOldPlanComponent {
  plans: any;

  user_id = ''
  plan_id = new FormControl("", Validators.required);
  months = new FormControl("", [Validators.min(0), Validators.max(12), Validators.required]);

  planForm: FormGroup = new FormGroup({
    plan_id: this.plan_id,
    months: this.months
  });

  saving = false;

  constructor(
    private api: ApiRequestService,
    public dialogRef: MatDialogRef<UsersAddOldPlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.user_id = data.user.id

    this.api.Get("products").subscribe((res) => {
      this.plans = res;
      console.log(res);
    });


  }

  addPlan(): void {
    console.log("add plan");
    this.saving = true;
    this.api.Post(`user/${this.user_id}/oldplan/${this.plan_id.value}`, {
      months_count: this.months.value,
      periodicity: 'monthly',
      origin: 'admin'
    }).subscribe(res => {
      this.dialogRef.close(res)
    })
  }

  Close(): void {
    this.dialogRef.close();
  }
}
