import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Config } from "../../../utils/Config";
import { User } from '../../../utils/User';

import { ApiRequestService } from '../../../services/api-request.service';

import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';


@Component({
  selector: 'app-usuarios-trocar-senha',
  templateUrl: './usuarios-trocar-senha.component.html',
  styleUrls: ['./usuarios-trocar-senha.component.scss']
})
export class UsuariosTrocarSenhaComponent implements OnInit {

	usuario:User = new User();

	cadastro:any = {
		info: {}
	};


	enviandoForm:boolean = false;

	formCadastro:FormGroup= new FormGroup({
		password: new FormControl('', [Validators.required, Validators.minLength(6)]),
		password2: new FormControl('', [Validators.required, Validators.minLength(6), this.CheckPassword])
	});

	config:any = Config;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private notifications: NotificationsService,
		private api:ApiRequestService
	) {
	}

	ngOnInit() {
	}

	CheckPassword(control: AbstractControl): { [key: string]: boolean } | null {

	    if (control.parent!=undefined && control.parent.get('password').value!=control.parent.get('password2').value) {
	        return { 'pwdMatch': true };
	    }
	    return null;
	}

	GetField(field:string, form:FormGroup = this.formCadastro){
		return form.get(field);
	}

	GetError(field:string, form:FormGroup = this.formCadastro){
		if(form.get(field).hasError('required')){
			return "Este campo é obrigatório";
		} else if(form.get(field).hasError('email')){
			return "E-mail inválido";
		} else if(form.get(field).hasError('minlength')){
			return "A senha precisa ter no mínimo 6 caracteres";
		} else if(form.get(field).hasError('pwdMatch')){
			return "As senhas não coincidem";
		} else if(form.get(field).hasError('emailTaken')){
			return "Este e-mail já está cadastrado no SimSave. ";
		} else {
			return "Preencha este campo corretamente";
		}
	}

	Cadastrar(){

		if(this.formCadastro.valid){
			let v = this.formCadastro.value;

			let url = "../user/changePassword";

			this.enviandoForm = true;
			this.api.Post(url, v).subscribe((response:any)=>{

				this.enviandoForm = false;
				this.notifications.create("Sucesso!", "Usuário salvo com sucesso", NotificationType.Success, { timeOut: 2500 } );

				this.formCadastro.setValue({
					password: "",
					password2: ""
				});

			}, (error:any)=>{
				this.enviandoForm = false;

				this.notifications.create("Erro!", "Erro ao cadastrar usuário. Tente novamente mais tarde.", NotificationType.Error, { timeOut: 5000 } );
				console.error(error.error);

			});
		}
	}

}
