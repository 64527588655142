import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from "../../../services/api-request.service";
import { PageEvent } from '@angular/material/paginator';
import { NotificationsService, NotificationType } from 'angular2-notifications';

import { Config } from "../../../utils/Config";
import { User } from '../../../utils/User';

@Component({
  selector: 'app-instructors',
  templateUrl: './instructors.component.html',
  styleUrls: ['./instructors.component.scss']
})
export class InstructorsComponent implements OnInit {

	currentUser:User = new User();

	instructors:any;
	columnsToDisplay = ["name", "email", "acoes"];

	filters:any = {
		justMine: true
	}


	constructor(
		private _notifications: NotificationsService,
		public apiRequest: ApiRequestService
	) { }

	ngOnInit() {
		this.LoadData();
	}


	LoadData(pag:number = 1, pageSize:number = 25){

		this.instructors = undefined;

		let url = "user?page="+(pag)+"&pageSize="+pageSize;
		url += "&role=instructor";
		if(this.filters.justMine) url += "&coordenated";

		this.apiRequest.Get(url).subscribe((ret:any)=>{
			//console.log(ret);
			this.instructors = ret;
		});

	}

	public ChangeFilter(){
		this.LoadData();
	}

	public ChangePage(event?:PageEvent){
		console.log(event);
		this.LoadData(event.pageIndex+1, event.pageSize);
	}

}
