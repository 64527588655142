import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { Config } from "../../utils/Config";
import {ActivatedRoute} from "@angular/router";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {ApiRequestService} from "../../services/api-request.service";
import {bool} from "aws-sdk/clients/signer";
@Component({
	selector: 'app-user-products',
	templateUrl: './user-products.component.html',
	styleUrls: ['./user-products.component.scss']
})
export class UserProductsComponent implements OnInit {
	user_id:number;
	loading:any = false;
	dados:any;
	produtos:any;
	produtosFiltrados:any;
	produtoSelecionado: number;

	constructor(
		private _notifications: NotificationsService,
		public apiRequest: ApiRequestService,
		public dialogRef: MatDialogRef<UserProductsComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData:any,
		public dialog: MatDialog
	) {
	}

	ngOnInit() {
		this.CarregarDados( this.dialogData.id );

	}

	CarregarDados(id){
		
		this.loading = true;
		this.produtoSelecionado = null;

		this.apiRequest.Get("user/"+id+"/products").subscribe((ret:any)=>{
			this.dados = ret;
			this.user_id = id;
			this.loading = false;

			this.apiRequest.Get("products?showAll=true").subscribe((ret:any)=>{
				this.produtos = ret;
				this.user_id = id;
				this.filtraProdutosAdicionados();
			});

		});

	}

	filtraProdutosAdicionados() {
		this.produtosFiltrados = this.produtos.filter((item) => { 
			let contem = this.dados.filter((dado) => { return (dado.id == item.id) }).length;
			if(contem) { return false; } else { return true; } 
		})
	}

	Adicionar() {
		if(this.produtoSelecionado == null || this.produtoSelecionado == undefined) {
			alert("Selecione um produto.");
		} else {
			this.apiRequest.Post("user/"+this.user_id+"/products", {product_id: this.produtoSelecionado }).subscribe((ret:any) => 
			{
				this.CarregarDados(this.dialogData.id);
			})
		}
	}

	Deletar(id) {
		if(confirm("Confirma?")) {
			this.apiRequest.Delete("user/"+this.user_id+"/products?product_id="+id).subscribe(
			(ret:any)=>{
				this.CarregarDados(this.dialogData.id);
			})
		}
	}

	Close(){
		this.dialogRef.close();
	}

	AlterarProdutos(product:any){

		if(product.option._selected)
		{
			this.apiRequest.Post("user/"+this.user_id+"/products", {product_id: product.option.value.id}).subscribe((ret:any)=>{
				this._notifications.create("Sucesso!", "Produtos alterados com sucesso", NotificationType.Success, { timeOut: 2500 } );
			}, (error)=>{
				this._notifications.create("Erro!", "Erro ao alterar os vinculos dos produtos ao usuário, tente novamente mais tarde", NotificationType.Error, { timeOut: 5000 } );
			});

		}
		else
		{
			this.apiRequest.Delete("user/"+this.user_id+"/products?product_id="+product.option.value.id).subscribe((ret:any)=>{
				this._notifications.create("Sucesso!", "Produtos alterados com sucesso", NotificationType.Success, { timeOut: 2500 } );
			}, (error)=>{
				this._notifications.create("Erro!", "Erro ao alterar os vinculos dos produtos ao usuário, tente novamente mais tarde", NotificationType.Error, { timeOut: 5000 } );
			});

		}

	}


}
