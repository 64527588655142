import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ApiRequestService } from '../../services/api-request.service';

@Component({
  selector: 'app-search-studio-block',
  templateUrl: './search-studio-block.component.html',
  styleUrls: ['./search-studio-block.component.scss']
})
export class SearchStudioBlockComponent {

	data:any = {
		apiSubscribe: null,
		loading: false,
		list: []
	};

	selectedDate:Date = new Date();

	constructor(
		private api:ApiRequestService,
		public dialogRef: MatDialogRef<SearchStudioBlockComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData:any
	) {
		this.LoadData();
	}

	LoadData(){

		this.data.list = [];
		this.data.loading = true;

		let date = this.selectedDate.toISOString().substr(0,10);
		this.api.Get("studio/calendar?start="+date+"&end="+date).subscribe((res:any)=>{
			console.log(res);
			this.data.list = res;
			this.data.loading = false;
		});
	}

	SelectData(data:any){
		console.log(data);
		this.dialogRef.close(data.data);
	}

	Close(){
		this.dialogRef.close();
	}

}
