import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from "../../../services/api-request.service";
import { EventEmitterService } from "../../../services/event-emitter.service";
import { Router  } from '@angular/router';

@Component({
	selector: 'app-topics-list',
	templateUrl: './topics-list.component.html',
	styleUrls: ['./topics-list.component.scss']
})
export class TopicsListComponent implements OnInit {

	topics:any[];
	refreshProductSubscription:any;

	constructor(
		private router: Router,
		private api:ApiRequestService
	) { }

	ngOnInit() {
		this.LoadTopics();

		this.refreshProductSubscription = EventEmitterService.get("refreshProduct").subscribe((res)=>{
			this.topics = [];
			this.LoadTopics();
 		});

		
 		this.router.events.subscribe((event) => {
			this.refreshProductSubscription.unsubscribe();
		});
	}

	LoadTopics(){
		this.api.Get("topic").subscribe((res:any[])=>{
			console.log(res);
			this.topics = res;
		});
	}

	RemoveTopic(data:any){

		if(!confirm("Tem certeza que deseja remover este tópico?")) return;

		this.api.Delete("../topic/"+data.id).subscribe((res:any[])=>{
			this.LoadTopics();
		});
	}

}
