import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ApiRequestService } from '../../../services/api-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { CleanTextForURLPipe } from '../../../pipes/clean-text-for-url.pipe';
import { Validation } from '../../../utils/Validation';
import { EventEmitterService } from '../../../services/event-emitter.service';

@Component({
  selector: 'app-simulator-casos-form',
  templateUrl: './simulator-casos-form.component.html',
  styleUrls: ['./simulator-casos-form.component.scss']
})
export class SimulatorCasosFormComponent extends Validation implements OnInit {

	formCadastro:FormGroup= new FormGroup({
		name: new FormControl('', Validators.required),
		mission_type: new FormControl('', Validators.required),
		interface_type: new FormControl('', Validators.required),
		environment_type: new FormControl('', Validators.required),
		patient_history: new FormControl(''),
		patient_initial_approach: new FormControl('')
	});
	enviandoForm = false;

	parametros:any[] = undefined;
	anamnesis:any[] = undefined;
	
	dados:any = {
		paciente: [],
		anamnesis: [],
		patient_data: {
			min_age: 0,
			max_age: 0,
			min_weight: 0,
			max_weight: 0,
			gender: []
		}
	};
	queryParams:any;

	missionTypes:any[] = [
		{
			key: "treatment",
			label: "Tratamento"
		},
		{
			key: "diagnosis",
			label: "Diagnóstico"
		},
	];

	interfaceTypes:any[] = [
		{
			key: "abcde",
			label: "ABCDE"
		},
		{
			key: "osce",
			label: "Osce"
		},
	];

	environmentTypes:any[] = [
		{
			key: "red_room",
			label: "Sala Vermelha"
		},
		{
			key: "studio",
			label: "Estúdio"
		},
	];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private notifications: NotificationsService,
		private api:ApiRequestService,
		private cleanText:CleanTextForURLPipe
	) {
		super();

		this.route.params.subscribe(ret => {
			if(ret.id!=undefined){
				this.dados.id = ret.id;
				this.CarregarDados(ret.id);
			}
		});

	}

	ngOnInit() {
		this.route.params.subscribe(ret => {
			this.queryParams = ret;
			this.CarregarParametros();
			this.CarregarAnamnese();

			if(this.queryParams.type=="protocol"){
				this.formCadastro = new FormGroup({
					name: new FormControl('', Validators.required)
				});
			}
		});
	}


	CarregarDados(id:any){

		this.api.Get("simulator/"+id).subscribe((response:any)=>{

			if(this.queryParams.type=="protocol"){
				this.formCadastro.get("name").setValue(response.name);
			} else {
				this.formCadastro.get("name").setValue(response.name);
				this.formCadastro.get("mission_type").setValue(response.mission_type);
				this.formCadastro.get("interface_type").setValue(response.interface_type);
				this.formCadastro.get("environment_type").setValue(response.environment_type);
				this.formCadastro.get("patient_history").setValue(response.patient_history);
				this.formCadastro.get("patient_initial_approach").setValue(response.patient_initial_approach);
				this.dados.paciente = (response.patient_parameters);
				this.dados.anamnesis = (response.anamnesis);

				if(response.patient_data) this.dados.patient_data = (response.patient_data);
			}

			console.log(this.dados);
		}, error=>{
			console.error("ERRO", error)
		});

	}

	CarregarParametros(){

		this.api.Get("simulator/parameters").subscribe((response:any[])=>{
			this.parametros = response;
			console.log(this.parametros);
		}, error=>{
			console.error("ERRO", error)
		});

	}

	CarregarAnamnese(){

		this.api.Get("simulator/anamnesis").subscribe((response:any)=>{


			for(let i:number=0; i<response.data.length; i++){
				//response.data[i].options = JSON.parse(response.data[i].options);
				//response.data[i].questions = JSON.parse(response.data[i].questions);
				response.data[i].multiple = response.data[i].multiple==1;
			}

			this.anamnesis = response.data;
			console.log(this.anamnesis);
		}, error=>{
			console.error("ERRO", error)
		});

	}

	PacienteAddAnamnese() {
		this.dados.anamnesis.push({
			id: undefined,
			opt: []
		});
	}

	PacienteRemoveAnamnese(index) {
		this.dados.anamnesis.splice(index, 1);
	}

	PacienteAddParam() {
		this.dados.paciente.push({
			id: undefined,
			value: [],
			valueMax: []
		});
	}

	PacienteRemoveParam(index) {
		this.dados.paciente.splice(index, 1);
	}

	RetornarParametro(idParam):any{
		for(let i:number=0; i<this.parametros.length; i++){
			if(this.parametros[i].id==idParam){
				//console.log(this.parametros[i]);
				return this.parametros[i];
			}
		}

		return undefined;
	}

	RetornarAnamnese(idParam):any{
		for(let i:number=0; i<this.anamnesis.length; i++){
			if(this.anamnesis[i].id==idParam){
				return this.anamnesis[i];
			}
		}

		return undefined;
	}

	CleanText(valor){
		return this.cleanText.transform(valor);
	}

	Cadastrar(){

		let data;
		if(this.queryParams.type=="case"){
			data = {
				name: this.formCadastro.get("name").value,
				type: this.queryParams.type,
				mission_type: this.formCadastro.get("mission_type").value,
				environment_type: this.formCadastro.get("environment_type").value,
				interface_type: this.formCadastro.get("interface_type").value,
				patient_history: this.formCadastro.get("patient_history").value,
				patient_initial_approach: this.formCadastro.get("patient_initial_approach").value,
				patient_parameters: JSON.stringify(this.dados.paciente),
				anamnesis: JSON.stringify(this.dados.anamnesis),
				patient_data: JSON.stringify(this.dados.patient_data)
			}
		} else if(this.queryParams.type=="protocol"){
			data = {
				name: this.formCadastro.get("name").value,
				type: this.queryParams.type,
				mission_type: "",
				environment_type: "",
				interface_type: "",
				patient_parameters: "",
				anamnesis: "",
			}
		}

		console.log(data);

		if(!this.formCadastro.valid){
			return;
		}

		this.enviandoForm = true;

		let url = "simulator";
		if(this.dados.id){
			url = url+"/"+this.dados.id;
		}
		
		this.api.Post(url, data).subscribe((response:any)=>{
			this.notifications.create("Sucesso!", "Caso salvo com sucesso!", NotificationType.Success, { timeOut: 5000 } );
			EventEmitterService.get("deleteAllRouteReuse").emit();
			this.router.navigate(['/simulador/'+this.queryParams.type])
		}, (error:any)=>{
			this.enviandoForm = false;

			this.notifications.create("Erro!", "Erro ao cadastrar usuário. Tente novamente mais tarde.", NotificationType.Error, { timeOut: 5000 } );
			console.error(error.error);

		});
	}

}
