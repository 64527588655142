import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from '../../../services/api-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as Player from "@vimeo/player/dist/player.js";
import { NotificationsService, NotificationType } from 'angular2-notifications';

import {MatDialog} from '@angular/material';
import { MinhasAulasVideoAddRevisaoComponent } from '../minhas-aulas-video-add-revisao/minhas-aulas-video-add-revisao.component';
import { MinhasAulasVideoAddSumarioComponent } from '../minhas-aulas-video-add-sumario/minhas-aulas-video-add-sumario.component';
import { MinhasAulasAddVideoComponent } from '../minhas-aulas-add-video/minhas-aulas-add-video.component';
import { User } from '../../../utils/User';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';


@Component({
	selector: 'app-minhas-aulas-video',
	templateUrl: './minhas-aulas-video.component.html',
	styleUrls: ['./minhas-aulas-video.component.scss']
})
export class MinhasAulasVideoComponent implements OnInit {

	aula:any = undefined;
	video:any = undefined;
	player:any = undefined;
	revisions:any[] = undefined;
	summary:any[] = undefined;
	comments:any[] = undefined;
	params:any;
	usuario:User = new User();

	formComment:FormGroup= new FormGroup({
		text: new FormControl('', Validators.required)
	});

	constructor(
		public dialog: MatDialog,
		private route: ActivatedRoute,
		private router: Router,
		private notifications: NotificationsService,
		private api:ApiRequestService
		) {
	}

	ngOnInit() {

		this.route.params.subscribe(ret => {
			this.params = ret;
			this.CarregarAula();
		});

	}

	CarregarVideo(){

		if(this.player!=undefined){
			document.getElementById("videoPlayer").innerHTML = "";
			this.player.destroy();
		}

		this.api.Get("video/"+this.params.id_video).subscribe(
			(res:any) => {
				console.log(res);
				this.video = res;

				let idVideo = this.video.uri.replace("/videos/", "");
				console.log(idVideo);

				window.setTimeout(()=>{
					this.player = new Player('videoPlayer', {
						//url: "https://vimeo.com/"+idVideo,
						id: idVideo,
						responsive: true
					});
				}, 1000);

				this.CarregarRevisoes();
				this.CarregarSumario();
				this.LoadComments();

			},
			error => {
				console.log(error);
				alert("Erro ao carregar");
			})
	}

	CarregarAula(){
		this.api.Get("content/"+this.params.id_aula).subscribe(
			(res:any) => {
				console.log(res);
				this.aula = res;
				this.CarregarVideo();
			},
			error => {
				console.log(error);
			})
	}

	CarregarRevisoes(){

		this.api.Get("video/"+this.params.id_video+"/revision").subscribe(
			(res:any) => {
				this.revisions = res;
			},
			error => {
				console.log(error);
				alert("Erro ao carregar revisões");
			})
	}

	LoadComments(){

		this.api.Get("video/"+this.params.id_video+"/comment").subscribe(
			(res:any) => {
				this.comments = res;
			},
			error => {
				console.log(error);
				alert("Erro ao carregar comentários");
			})
	}

	AddComment(){

		if(!this.formComment.valid) return;

		this.api.Post("video/"+this.params.id_video+"/comment", this.formComment.value).subscribe(
			(res:any) => {
				this.comments.push(res);
				this.formComment.get("text").setValue("");
			},
			error => {
				console.log(error);
				alert("Erro ao adicionar comentário");
			})

	}

	RemoveComment(e, i:number){

		this.api.Delete("video/"+this.params.id_video+"/comment/"+e.id).subscribe(
			(res:any) => {
				this.comments.splice(i, 1);
			},
			error => {
				console.log(error);
				alert("Erro ao remover comentário");
			})

	}

	CountRevisions():number{

		let qtd:number = 0;

		if(this.aula){
			for(let i:number=0; i<this.revisions.length; i++){
				if(!this.revisions[i].corrected) qtd++;
			}
		}

		return qtd;
	}

	AddRevisao(revisao=undefined){
		this.player.pause();

		this.player.getCurrentTime().then((seconds)=>{
			console.log(seconds);

			const dialogRef = this.dialog.open(MinhasAulasVideoAddRevisaoComponent, {
				width: '600px',
				data: {
					content_id: this.params.id_aula,
					video: this.video,
					seconds: seconds,
					revisao: revisao
				}
			});

			dialogRef.afterClosed().subscribe(res => {
				
				if(res!=undefined){
					let adicionado:boolean = false;
					for(let i=0; i<this.revisions.length; i++){
						if(this.revisions[i].id==res.id){
							adicionado = true;
							this.revisions[i] = res;
						}
					}

					if(!adicionado)
						this.revisions.push(res);

				}
				this.player.play();
			});

		});
	}

	RemoverRevisao(rev){

		if(!confirm("Tem certeza que deseja remover esta revisão?")){
			return;
		}

		this.api.Delete("video/"+this.params.id_video+"/revision/"+rev.id).subscribe(
			(res:any) => {
				console.log("removido com sucesso!");

				for(let i:number=0; i<this.revisions.length; i++){
					if(this.revisions[i].id==rev.id){
						this.revisions.splice(i, 1);
						return;
					}
				}
			},
			error => {
				console.log(error);
				alert("Erro ao remover revisões");
			})
	}

	UpdateVideo(aula): void {
		const dialogRef = this.dialog.open(MinhasAulasAddVideoComponent, {
			width: '600px',
			data: { 
				aula,
				params: this.params,
				multiple: false,
			}
		});

		dialogRef.afterClosed().subscribe(
			(res) => {
				if (res) {
					Object.assign(this.video, res);

					let idVideo = this.video.uri.replace("/videos/", "");

					window.setTimeout(()=>{
						this.player = new Player('videoPlayer', {
							//url: "https://vimeo.com/"+idVideo,
							id: idVideo,
							responsive: true
						});
					}, 1000);
				}
		}, (error) => {
				console.log(error);
				alert("Error ao carregar video!");
			});
	}

	RemoverVideo(rev){

		if(!confirm("Tem certeza que deseja remover este vídeo?")){
			return;
		}

		this.api.Delete("video/"+this.params.id_video).subscribe(
			(res:any) => {
				this.notifications.create("Sucesso!", "Vídeo removido com sucesso!", NotificationType.Success, { timeOut: 3000 } );

				this.router.navigate(["/minhas_aulas"])
			},
			error => {
				console.log(error);
				alert("Erro ao remover video");
			})
	}

	SetarTempoVideo(time:number){
		this.player.setCurrentTime(time);
		this.player.play();
	}


	AlterarStatusRevisao(revisao:any){

		let data = { corrected: revisao.corrected };

		console.log(revisao, data );
		
		this.api.Post("video/"+this.params.id_video+"/revision/"+revisao.id+"/correction", data).subscribe(
			(res:any) => {
				console.log(res);
			},
			error => {
				console.log(error);
				
				this.notifications.create("Erro!", "Não foi possível salvar o status da revisão!", NotificationType.Error, { timeOut: 2500 } );

			})

	}


	AlterarStatusVideo(status){

		let data:any = {
			status: status
		}

		if(status=="reproved"){
			let txt = prompt("Digite o motivo da reprovação", this.aula.reproval_reason ? this.aula.reproval_reason : "");
			if(txt==null){
				return;
			} else {
				data.reproval_reason = txt;
				this.aula.reproval_reason = txt;
			}
		}
		
		this.aula.status = status;

		this.api.Post("content/"+this.params.id_aula, data).subscribe((res:any)=>{
			console.log(res);

			this.notifications.create("Sucesso!", "Status alterado com sucesso!", NotificationType.Success, { timeOut: 5000 } );

		}, error=> {
			alert("Erro ao realizar ação. Contate o administrador");
			console.log(error.error);
		})

	}


	CarregarSumario(){

		this.api.Get("video/"+this.params.id_video+"/summary").subscribe(
			(res:any) => {
				console.log(res);
				this.summary = res;
			},
			error => {
				console.log(error);
				alert("Erro ao carregar sumario");
			})
	}


	AddSumario(sumario=undefined){
		this.player.pause();

		this.player.getCurrentTime().then((seconds)=>{
			console.log(seconds);

			const dialogRef = this.dialog.open(MinhasAulasVideoAddSumarioComponent, {
				width: '600px',
				data: {
					video: this.video,
					seconds: seconds,
					sumario: sumario
				}
			});

			dialogRef.afterClosed().subscribe(res => {
				
				if(res!=undefined){
					let adicionado:boolean = false;
					for(let i=0; i<this.summary.length; i++){
						if(this.summary[i].id==res.id){
							adicionado = true;
							this.summary[i] = res;
						}
					}

					if(!adicionado)
						this.summary.push(res);

				}
				this.player.play();
			});

		});
	}


	RemoverSumario(rev){

		if(!confirm("Tem certeza que deseja remover este item do sumário?")){
			return;
		}

		this.api.Delete("video/"+this.params.id_video+"/summary/"+rev.id).subscribe(
			(res:any) => {
				console.log("removido com sucesso!");

				for(let i:number=0; i<this.summary.length; i++){
					if(this.summary[i].id==rev.id){
						this.summary.splice(i, 1);
						return;
					}
				}
			},
			error => {
				console.log(error);
				alert("Erro ao remover sumário");
			})
	}

}
