import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ApiRequestService } from '../../services/api-request.service';

@Component({
  selector: 'app-journey-step-search',
  templateUrl: './journey-step-search.component.html',
  styleUrls: ['./journey-step-search.component.scss']
})
export class JourneyStepSearchComponent {

	data:any = {
		apiSubscribe: null,
		loading: false,
		data: null
	};
	searchText:string = "";

  	constructor(
		private api:ApiRequestService,
		public dialogRef: MatDialogRef<JourneyStepSearchComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData:any,
		public dialog: MatDialog
	) { }


 	LoadData(page:number = 1, pagesize:number = 25){

		this.data.loading = true;
		if( this.data.apiSubscribe ) this.data.apiSubscribe.unsubscribe();

		let url = "journey/search?q="+this.searchText+"&types="+this.dialogData.types;
		this.data.apiSubscribe = this.api.Get(url).subscribe((res:any)=>{
			this.data.loading = false;
			this.data.data = res;
		})

	}

	Search(){
		if(this.searchText.length>=3){
			this.LoadData();
		}
	}


	SelectItem(data:any){
		this.dialogRef.close(data);
	}


	Close(){
		this.dialogRef.close();
	}

}
