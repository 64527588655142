import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';
import { ApiRequestService } from "../../../services/api-request.service";
import { PageEvent } from '@angular/material/paginator';
import { ExcelServicesService } from '../../../services/excel-services.service';

@Component({
  selector: 'app-report-voucher-details',
  templateUrl: './report-voucher-details.component.html',
  styleUrls: ['./report-voucher-details.component.scss']
})
export class ReportVoucherDetailsComponent implements OnInit {

	table:any;
  data: any;
  code:any;
  columnsToDisplay: string[] = [];
  selectedDateStart: Date = null;
  selectedDateEnd:Date = null;

  constructor(
    private changeRef: ChangeDetectorRef,
		public apiRequest: ApiRequestService,
		private route: ActivatedRoute,
		private router: Router,
		private excelService:ExcelServicesService
	) { }

	ngOnInit() {

    this.route.params.subscribe((p: any) => {
      this.code = p.code;
			this.table = 'Detalhes do Voucher';
			this.LoadReport();
		});

	}

	LoadReport(pag:number = 0, pageSize:number = 25){

    this.apiRequest.Post(`report/voucher/${this.code}?page=${(pag + 1)}&pagesize=${pageSize}`, { start: this.selectedDateStart, end: this.selectedDateEnd }).subscribe((res: any) => {

      res.data = this.formatData(res.data);

      this.data = res;

      if (this.data.total > 0) {
        this.columnsToDisplay = Object.keys(this.data.data[0]);
      }

      this.changeRef.detectChanges();

		});

	}

	public ChangePage(event?:PageEvent){
		this.LoadReport(event.pageIndex, event.pageSize);
	}

	exportAsXLSX():void {
		this.apiRequest.Post(`report/voucher/${this.code}?page=1&pagesize=999999`, { start: this.selectedDateStart, end: this.selectedDateEnd }).subscribe((res:any)=>{

      res.data = this.formatData(res.data);

			this.excelService.exportAsExcelFile(res.data, this.table);
		});
  }

  formatData(data) {

    data = data.map((item) => ({
        'Id Venda': item.id,
        'Nome do Cliente': (item.user ? item.user.name : '-'),
        'Cidade': (item.user && item.user.info && item.user.info.city_obj ? item.user.info.city_obj.name : '-'),
        'Produto': (item.product ? item.product.name : '-'),
        'Valor Total da Compra': item.total_value,
        'Código Voucher': item.voucher,
        'Status': item.status,
        'Utilizado em': item.created
    }));

    return data;

  }

}
