import { Component, OnInit, ViewChild } from '@angular/core';
import { InstructorsFormComponent } from '../instructors-form/instructors-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitterService } from '../../../services/event-emitter.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-instructors-form-dialog',
  templateUrl: './instructors-form-dialog.component.html',
  styleUrls: ['./instructors-form-dialog.component.scss']
})
export class InstructorsFormDialogComponent implements OnInit {

	@ViewChild('form') form:InstructorsFormComponent;
	sending:boolean = false;

	constructor(
		public dialogRef: MatDialogRef<InstructorsFormDialogComponent>,
		private router: Router
	) { }

	ngOnInit() {
		this.form.parent = this;
	}

	Save() {
		this.sending = true;
		this.form.Save();
	}

	SaveSuccess(data:any) {
		EventEmitterService.get("deleteAllRouteReuse").emit();
		this.dialogRef.close(data);
		this.sending = false;
	}

	SaveFail() {
		this.sending = false;
	}

	Close(): void {
		this.dialogRef.close();
	}


}
