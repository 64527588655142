import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ApiRequestService } from '../../../services/api-request.service';

@Component({
  selector: 'app-minhas-aulas-video-add-revisao',
  templateUrl: './minhas-aulas-video-add-revisao.component.html',
  styleUrls: ['./minhas-aulas-video-add-revisao.component.scss']
})
export class MinhasAulasVideoAddRevisaoComponent {

	salvando:boolean = false;
	revisao:any = {
		time: "00:00",
		revision: "",
	};

	constructor(
		private api:ApiRequestService,
		public dialogRef: MatDialogRef<MinhasAulasVideoAddRevisaoComponent>,
		@Inject(MAT_DIALOG_DATA) public data:any
	) {

		let s;
		if(data.revisao){
			s = data.revisao.time;
			this.revisao.revision = data.revisao.revision;
			this.revisao.id = data.revisao.id;
		} else {
			s = Math.round(Math.max(0, data.seconds-5));
			
		}

		let date = new Date(null);
		date.setSeconds(s);
		let result = date.toISOString().substr(14, 5);

		this.revisao.time = result;


	}


	Close(): void {
		this.dialogRef.close();
	}

	Salvar(){
		this.salvando = true;

		let t = [this.revisao.time.substr(0,2), this.revisao.time.substr(2,2)];
		console.log(t);

		let data = {
			content_id: this.data.content_id,
			revision: this.revisao.revision,
			time: parseInt(t[0])*60+parseInt(t[1])
		}


		let url = this.revisao.id ? "video/"+this.data.video.id+"/revision/"+this.revisao.id : "video/"+this.data.video.id+"/revision";
		
		this.api.Post(url, {revision: data}).subscribe(
			(res:any) => {
				this.salvando = false;
				this.dialogRef.close(res);
			},
			error => {
				this.salvando = false;
				console.log(error);
				alert("Erro ao adicionar os vídeos");
			})
	}

}
