import { Component, OnInit } from '@angular/core';
import { User } from '../../utils/User';
import { ApiRequestService } from "../../services/api-request.service";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

	notifications:any;
	list:any[] = [];
	loading:boolean = false;

	filters:any = [
		{
			key: "",
			value: "Todos"
		},
		{
			key: "content_status",
			value: "Status do conteúdo"
		},
		{
			key: "content_video_revision",
			value: "Revisão em vídeo"
		},
		{
			key: "content_video_comment",
			value: "Comentário em vídeo"
		},
	];

	selectedFilter:string;

  	constructor(
		public api:ApiRequestService
	) { }

	ngOnInit() {
		this.LoadNotifications();
	}

	LoadNotifications(){

		let url:string = "notifications?";

		if(this.notifications){
			url = url+"page="+(this.notifications.current_page+1);
		}

		if(this.selectedFilter){
			url = url+"&type="+this.selectedFilter;
		}

		this.loading = true;

		this.api.Get(url).subscribe((res:any)=>{
			console.log(res);
			this.notifications = res;
			this.list = this.list.concat(res.data);
			this.loading = false;
		})

	}

	Filter($event){
		console.log("FILTER", this.selectedFilter);
		this.notifications = undefined;
		this.list = [];

		this.LoadNotifications();
	}

}
