import { Pipe, PipeTransform } from '@angular/core';
import { isDevMode } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
	name: 'checkImageUrl'
})
export class CheckImageUrlPipe implements PipeTransform {

	transform(value: any, aws:boolean=false, size?: any): any {


		if(!value) {
			return "";
		} else if(aws){
			if(size){
				return value.replace("{size}", size);
			} else {
				return value.replace("{size}/", "");
			}
		} else {
			return environment.apiUrl+"../arquivos/"+value;
		}
	}

}
