import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { User } from '../../../utils/User';
import { ApiRequestService } from '../../../services/api-request.service';
import { Validation } from '../../../utils/Validation';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Component({
  selector: 'app-check-user-info',
  templateUrl: './check-user-info.component.html',
  styleUrls: ['./check-user-info.component.scss']
})
export class CheckUserInfoComponent extends Validation implements OnInit {

	currentUser:User = new User();

	states:any[] = [];
	cities:any[] = [];
	params:any;

	InstructorForm:FormGroup = new FormGroup({
		name: new FormControl('', Validators.required),
		info: new FormGroup({
			phone: new FormControl('', Validators.required),
			nationality: new FormControl('', Validators.required),
			marital_status : new FormControl('', Validators.required),
			occupation : new FormControl('', Validators.required),
			identity: new FormControl('', Validators.required),
			identity_expedition: new FormControl('', Validators.required),
			cpf: new FormControl('', Validators.required),
			address: new FormControl('', Validators.required),
			street_number  : new FormControl('', Validators.required),
			complement : new FormControl('', Validators.required),
			district: new FormControl('', Validators.required),
			zip_code: new FormControl('', Validators.required),
			state: new FormControl('', Validators.required),
			city: new FormControl('', Validators.required),
		}
	)});


	constructor(
		private notifications: NotificationsService,
		private route: ActivatedRoute,
		private router: Router,
		private api:ApiRequestService
	) {
		super();
	}

	ngOnInit() {

		this.route.params.subscribe(ret => {
			this.params = ret;
		});

		this.LoadData();
		this.LoadStates();
	}

	LoadData(){

		this.api.Get("../user/"+this.currentUser.GetData().id).subscribe((res:any)=>{
			
			this.InstructorForm.get("name").setValue(res.name);
			if(res.info){
				this.InstructorForm.get("info").get("phone").setValue(res.info.phone);
				this.InstructorForm.get("info").get("nationality").setValue(res.info.nationality);
				this.InstructorForm.get("info").get("marital_status").setValue(res.info.marital_status);
				this.InstructorForm.get("info").get("occupation").setValue(res.info.occupation);
				this.InstructorForm.get("info").get("identity").setValue(res.info.identity);
				this.InstructorForm.get("info").get("identity_expedition").setValue(res.info.identity_expedition);
				this.InstructorForm.get("info").get("cpf").setValue(res.info.cpf);
				this.InstructorForm.get("info").get("address").setValue(res.info.address);
				this.InstructorForm.get("info").get("street_number").setValue(res.info.street_number);
				this.InstructorForm.get("info").get("complement").setValue(res.info.complement);
				this.InstructorForm.get("info").get("district").setValue(res.info.district);
				this.InstructorForm.get("info").get("zip_code").setValue(res.info.zip_code);

				this.InstructorForm.get("info").get("state").setValue(res.info.state);
				if(res.info.city) this.InstructorForm.get("info").get("city").setValue(res.info.city.toString());
			}

			this.LoadCities();

		}, error=> {
			alert("Erro ao carregar dados. Contate o administrador");
			console.log(error.error);
		})
	}

	LoadStates(){

		this.api.Get("../estados").subscribe((response:any[])=>{
			this.states = response;
		}, error=>{
			console.error("ERRO", error)
		});

	}

	LoadCities(){

		console.log("Carregar cidades", this.InstructorForm.value);

		this.cities = [];

		this.api.Get("../cidades/"+this.InstructorForm.get('info').get('state').value).subscribe((response:any[])=>{
			this.cities = response;
		}, error=>{
			console.error("ERRO", error)
		});

	}

	sending:boolean = false;
	Save(){

		if(this.InstructorForm.valid){

			let url = "user/instructor/"+this.currentUser.GetData().id;

			this.sending = true;

			let data = this.InstructorForm.value;
			this.api.Post(url, data).subscribe((res:any)=>{
				//this.sending = false;
				this.SendContract();
				this.notifications.create("Sucesso!", "Dados salvos com sucesso", NotificationType.Success, { timeOut: 2500 } );
				//this.notifications.create("Contrato ", "Usuário salvo com sucesso", NotificationType.Success, { timeOut: 2500 } );
				//this.router.navigate(['/instructors']);
			}, ((res:any)=>{
				this.sending = false;
				console.error(res);
			}))

		}

	}

	SendContract(){
		this.api.Post("studio/block/"+this.params.studio_block_id+"/contract", {}).subscribe((res:any)=>{
			this.sending = false;
			this.notifications.create("Sucesso!", "Contrato enviado com sucesso", NotificationType.Success, { timeOut: 2500 } );
			this.router.navigate(["/"]);
		}, ((res:any)=>{
			this.sending = false;
			console.error(res);
		}))
	}

	GetCep(){
		this.api.GetCep(this.InstructorForm.get("info").get("zip_code").value).subscribe((res:any)=>{
			console.log(res);

			this.InstructorForm.get("info").get("address").setValue(res.logradouro);
			this.InstructorForm.get("info").get("district").setValue(res.bairro);
			this.InstructorForm.get("info").get("state").setValue(res.uf);
			
		})
	}

}
