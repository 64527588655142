import { Component, OnInit, Inject } from '@angular/core';
import { ApiRequestService } from '../../../services/api-request.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-simulator-casos-fluxograma-copiar-etapa-modal',
  templateUrl: './simulator-casos-fluxograma-copiar-etapa-modal.component.html',
  styleUrls: ['./simulator-casos-fluxograma-copiar-etapa-modal.component.scss']
})
export class SimulatorCasosFluxogramaCopiarEtapaModalComponent implements OnInit {

	operators:any[];

	constructor(
		public dialogRef: MatDialogRef<SimulatorCasosFluxogramaCopiarEtapaModalComponent>,
    	@Inject(MAT_DIALOG_DATA) public dataDialog:any,
		private api:ApiRequestService
	) { }

	ngOnInit() {
		this.LoadCasesOperators();
	}

	LoadCasesOperators(){
		this.api.Get("simulator/operators").subscribe((res:any)=>{
			this.operators = res;
		});
	}

	CopySelected(data:any) {
		let ids:number[] = [];
		data.selected.forEach((res)=>{
			ids.push(res.value.id);
		})

		this.dialogRef.close(ids);
	}

	Close(){
		this.dialogRef.close();
	}

}
