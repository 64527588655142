import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentJs'
})
export class MomentJsPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		moment.locale("pt-br");
		return moment(value).fromNow();
	}

}
