import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cleanTextForURL'
})
export class CleanTextForURLPipe implements PipeTransform {

	transform(value: any, args?: any): any {

		/* Remove unwanted characters, only accept alphanumeric and space */
		var keyword = value.replace(/[^A-Za-z0-9 ]/g,'');

		/* Replace multi spaces with a single space */
		keyword = keyword.replace(/\s{2,}/g,' ');

		/* Replace space with a '-' symbol */
		keyword = keyword.replace(/\s/g, "-");
		
		keyword = keyword.toLowerCase();

		return keyword;
	}

}
