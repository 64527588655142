import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ApiRequestService } from '../../../services/api-request.service';
import { Config } from "../../../utils/Config";

import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { User } from '../../../utils/User';
import { EventEmitterService } from '../../../services/event-emitter.service';
import { Validation } from '../../../utils/Validation';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UserSearchComponent } from '../../../dialogs/user-search/user-search.component';


@Component({
  selector: 'app-studio-block-form',
  templateUrl: './studio-block-form.component.html',
  styleUrls: ['./studio-block-form.component.scss']
})
export class StudioBlockFormComponent extends Validation implements OnInit {

	dataForm:FormGroup= new FormGroup({
		reason: new FormControl(""),
		type: new FormControl("", Validators.required),
		date: new FormControl(new Date(), Validators.required),
		coordenator: new FormControl(null),
		instructor: new FormControl(null),
		recording_count: new FormControl(1, Validators.required),
		start_time: new FormControl("", Validators.required),
		end_time: new FormControl("", Validators.required),
		medicine: new FormControl(true),
		nursing: new FormControl(false),
		nursing_tech: new FormControl(false),
		termo: new FormControl(false),
	});

	hours:string[] = [];
	currentUser:User = new User();
	config:any = Config;

	types:any = [];


	constructor(
		public dialogRef: MatDialogRef<StudioBlockFormComponent>,
    	@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private notifications: NotificationsService,
		private api:ApiRequestService,
		public dialog: MatDialog
		) {
		super();

		let ini:number = 9;
		let end:number = 20;

		if(this.currentUser.CheckPermissionArray(['videomaker', 'secretary', 'master'])){
			ini = 4;
			end = 23;

			this.types = [
				{key: "booked", value: "Estúdio reservado"},
				{key: "closed", value: "Estúdio fechado"},
				{key: "videoclass", value: "Videoaula"},
				{key: "interview", value: "Entrevista"},
				{key: "live", value: "Aula ao vivo"}
			];
		} else {
			this.types = [
				{key: "videoclass", value: "Videoaula"},
				{key: "interview", value: "Entrevista"},
			];
		}

		for(let i:number = ini; i<=end; i++){
			
			let h:string = i.toString();
			if(i<10) h = "0"+h;

			this.hours.push(h+":00");
			this.hours.push(h+":30");
		}

		if(this.dialogData && this.dialogData.id) this.LoadData();
	}

	ngOnInit() {
	}

	LoadData() {
		this.api.Get("studio/block/"+this.dialogData.id).subscribe((res:any)=>{

			let d:any = {
				reason: res.reason,
				type: res.type,
				date: new Date(res.date+" 12:00"),
				start_time: res.start_time.substr(0, 5),
				end_time: res.end_time.substr(0, 5),
				coordenator: res.coordenator,
				instructor: res.instructor,
				recording_count: res.recording_count,
				medicine: res.medicine,
				nursing: res.nursing,
				nursing_tech: res.nursing_tech,
				termo: true
			}
			console.log(d);
			this.dataForm.setValue(d);
		});
	}

	sending:boolean = false;
	Save(){

		if((!this.dataForm.get('coordenator').value || !this.dataForm.get('instructor').value) && !['booked', 'closed'].includes(this.GetField('type', this.dataForm).value)){
			alert("Selecione o coordenador e o professor responsável por esse agendamento");
			return;
		}

		if(!['booked', 'closed'].includes(this.GetField('type', this.dataForm).value) && !this.dataForm.get('termo').value){
			alert("Você precisa estar ciente do termo de imagem para reservar o estúdio");
			return;
		}

		if(this.dataForm.valid){

			let v = this.dataForm.value;

			if( parseInt(v.start_time.replace(":", ""))>=parseInt(v.end_time.replace(":", "")) ){
				this.notifications.create("Erro!", "A hora inicial não pode ser maior que a hora final", NotificationType.Error, { timeOut: 5000 } );
				return;
			}

			let url = "studio/block";

			if(this.dialogData && this.dialogData.id) url = url+"/"+this.dialogData.id;

			this.sending = true;
			this.api.Post(url, v).subscribe(res=>{
				this.notifications.create("Sucesso!", "Gravação da aula agendada com sucesso!", NotificationType.Success, { timeOut: 5000 } );
				EventEmitterService.get("calendar.update").emit();
				this.Close();

			}, error=> {
				this.sending = false;
				alert(error.error.error);
				this.notifications.create("Erro!", error.error.error, NotificationType.Error, { timeOut: 5000 } );
			})

		}
	}

	Close(){
		this.dialogRef.close();
	}

	SelectUser(role:string){

		const dialogRef = this.dialog.open(UserSearchComponent, {
			width: '450px',
			data: {
				role: role
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			this.dataForm.get(role).setValue(result);
		});

	}

}
