import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { SimulatorCasosFluxogramaCopiarEtapaModalComponent } from "../simulator-casos-fluxograma-copiar-etapa-modal/simulator-casos-fluxograma-copiar-etapa-modal.component";
import { SimulatorCasosFluxogramaEtapaModalComponent } from "../simulator-casos-fluxograma-etapa-modal/simulator-casos-fluxograma-etapa-modal.component";
import { SimulatorCasosFluxogramaProtocoloModalComponent } from "../simulator-casos-fluxograma-protocolo-modal/simulator-casos-fluxograma-protocolo-modal.component";
import { ActivatedRoute, Router } from '@angular/router';
import { ApiRequestService } from '../../../services/api-request.service';
import { PanZoomConfig, PanZoomAPI, PanZoomModel } from 'ng2-panzoom';
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
		selector: 'app-simulator-casos-fluxograma',
		templateUrl: './simulator-casos-fluxograma.component.html',
		styleUrls: ['./simulator-casos-fluxograma.component.scss']
})
export class SimulatorCasosFluxogramaComponent implements OnInit {

	flow:any;

	data:any = {
		operators: {
			0: {
				top: 120,
				left: 120,
				properties: {
					title: 'Início do Caso',
					class: "startCase",
					inputs: {},
					outputs: {
						0: {
							label: 'Iniciar',
						}
					}
				}
			},
			88888: {
				top: 120,
				left: 600,
				properties: {
					title: 'Sucesso',
					class: "endCase success",
					inputs: {
						input: {
							label: 'Finalizar',
						}
					},
					outputs: {}
				}
			},
			99999: {
				top: 200,
				left: 600,
				properties: {
					title: 'Falha',
					class: "endCase fail",
					inputs: {
						input: {
							label: 'Finalizar',
						}
					},
					outputs: {}
				}
			}
		},
		links: {}
	};

	linksTypes:any = {
		correct: "Correta",
		partially: "Parcialmente correta",
		wrong: "Errada",
		random: "Aleatória",
		dialog: "Caixa de Diálogo",
	};

	queryParam:any;
	selectedOperator:number = undefined;
	selectedLink:number = undefined;

	parametros:any[] = undefined;
	acoes:any[] = undefined;

	public panzoomConfig: PanZoomConfig = new PanZoomConfig({
		zoomOnMouseWheel: false,
		scalePerZoomLevel: 1.2,
		zoomLevels: 10,
		initialZoomLevel: 1
	});
	private panZoomAPI: PanZoomAPI;
	private apiSubscription: Subscription;
	public panzoomModel: PanZoomModel;

	constructor(
			private api:ApiRequestService,
			private route: ActivatedRoute,
			public dialog: MatDialog
		) {
		this.route.params.subscribe((p:any)=>{
			this.queryParam = p;
		})
	}

	ngOnInit() {
		
		this.apiSubscription = this.panzoomConfig.api.subscribe( (api: PanZoomAPI) => this.panZoomAPI = api );

		this.panzoomConfig.modelChanged.subscribe( (model: PanZoomModel) => this.onModelChanged(model) );

		this.CarregarFlow();

		this.CarregarAcoes();
		this.CarregarParametros();
	}

	exibirFlow(){
		setTimeout(()=>{

			this.flow = $('.flow').flowchart({
				data: this.data,
				defaultLinkColor: "grey",
				multipleLinksOnInput : true,
				
				onOperatorSelect: (operatorId:number)=>{

					if(operatorId==0 || operatorId==88888 || operatorId==99999) return;
					
					this.selectedOperator = operatorId;

					if(this.data.operators[operatorId].properties.type=='operator'){
						this.CarregarOperador(operatorId);
					}
					return true;
				},
				onOperatorUnselect: ()=>{
					this.CancelarOperador();
					this.selectedOperator = undefined;
					return true;
				},
				onOperatorMoved: (id:any, pos:any)=>{

					this.data.operators[id].top = pos.top;
					this.data.operators[id].left = pos.left;

					this.SalvarPosicaoOperador(id, pos);
				},
				onOperatorDelete: (operatorId:any)=>{
					console.log(operatorId);
					console.log(this.data.operators[operatorId]);
					if(operatorId>0 && this.data.operators[operatorId]!=undefined){
						this.deletarEtapaAct(operatorId);
					}
					return true;
				},
				onLinkCreate: (linkId:any, linkData:any)=>{
					if(linkId==0){
						this.SalvarLink(linkId, linkData);
					}
					return true;
				},
				onLinkDelete: (linkId:any, forced:any)=>{
					if(linkId>0 && this.data.links[linkId]!=undefined){
						this.deletarLinkAct(linkId);
					}
					return true;
				},
				onLinkSelect: (id:number)=>{
					this.selectedLink = id;
					return true;
				},
				onLinkUnselect: ()=>{
					this.selectedLink = undefined;
					return true;
				}
			});

			this.flow.flowchart('setPositionRatio', .832);
			this.flow.flowchart('redrawLinksLayer');

		}, 1000);
	}

	atualizarFlow(){
		this.flow.flowchart('setData', this.data);
	}

	onModelChanged(model: PanZoomModel): void {
		this.getCssScale(model.zoomLevel)
		if(this.flow){
			this.flow.flowchart('setPositionRatio', this.getCssScale(model.zoomLevel));
		}
	}

	private getCssScale(zoomLevel: any): number {
		return Math.pow(this.panzoomConfig.scalePerZoomLevel, zoomLevel - this.panzoomConfig.neutralZoomLevel);
	}

	zoomIn(): void {
		this.panZoomAPI.zoomIn();
	}

	zoomOut(): void {
		this.panZoomAPI.zoomOut();
	}

	resetView(): void {
		this.panZoomAPI.resetView();
	}

	SalvarPosicaoOperador(id:number, pos:any){

		let data = {
			pos_x: pos.left,
			pos_y: pos.top
		}

		if(id==0 || id==88888 || id==99999){

			let d:any;

			if(id==0){
				d = {
					flow_start_pos: JSON.stringify(data)
				};
			} else if(id==88888){
				d = {
					flow_success_pos: JSON.stringify(data)
				};
			} else if(id==99999){
				d = {
					flow_fail_pos: JSON.stringify(data)
				};
			}

			this.api.Post("simulator/"+this.queryParam.id, d).subscribe((res:any)=>{
			}, (error:any)=>{
				console.error(error);
			})

		} else {
			this.api.Post("simulator/"+this.queryParam.id+"/flow/operator/"+id, data).subscribe((res:any)=>{
				//console.log(res);
			}, (error:any)=>{
				console.error(error);
			})
		}

	}

	SalvarLink(id:number, linkData:any){

		console.log();

		let color = "";
		switch (this.flow.flowchart('getOperatorData', linkData.fromOperator).properties.outputs[linkData.fromConnector].type) {
			case "correct":
				color = "green";
				break;
			case "partially":
				color = "orange";
				break;
			case "wrong":
				color = "red";
				break;
			case "random":
				color = "purple";
				break;
			case "dialog":
				color = "teal";
				break;
			default:
				color = "blue";
				break;
		}

		let data = {
			from_operator: linkData.fromOperator,
			from_connector: linkData.fromConnector,
			to_operator: linkData.toOperator,
			color: color
		}

		this.api.Post("simulator/"+this.queryParam.id+"/flow/link", data).subscribe((res:any)=>{
			this.FlowUpdateLinks(res);
		}, (error:any)=>{
			console.error(error);
		})

	}

	deletarLinkAct(id:number){

		delete this.data.links[id];
		this.selectedLink = undefined;

		this.api.Delete("simulator/"+this.queryParam.id+"/flow/link/"+id).subscribe((res:any)=>{
			console.log("link removido");
		}, (error:any)=>{
			console.error(error);
		})
	}

	deletarLink(id:number){
		this.flow.flowchart('deleteLink', id);
	}

	novoProtocolo(id?:number): void {
		const dialogRef = this.dialog.open(SimulatorCasosFluxogramaProtocoloModalComponent, {
				width: '50%',
				data: {
					id_etapa: id,
					id_caso: this.queryParam.id
				}
		});

		dialogRef.afterClosed().subscribe(result => {
			if(result){
				this.FlowUpdateOperator(result);
			}
		});
	}

	novaEtapa(id:number): void {
		const dialogRef = this.dialog.open(SimulatorCasosFluxogramaEtapaModalComponent, {
				width: '80%',
				data: {
					id_etapa: id,
					id_caso: this.queryParam.id
				}
		});

		dialogRef.afterClosed().subscribe(result => {
			if(result){
				this.FlowUpdateOperator(result);
			}
		});
	}

	copiarEtapa(): void {
		const dialogRef = this.dialog.open(SimulatorCasosFluxogramaCopiarEtapaModalComponent, {
				width: '80%',
				data: {
					id_caso: this.queryParam.id
				}
		});

		dialogRef.afterClosed().subscribe(result => {
			if(result){
				result.forEach(val=>{
					this.api.Post("simulator/"+this.queryParam.id+"/flow/operator/"+val+"/duplicate", {}).subscribe((res:any)=>{
						console.log(res);
						this.FlowUpdateOperator(res);
					}, (error:any)=>{
						console.error(error);
					})
				})
			}
		});
	}

	deletarEtapaAct(id:number){

		delete this.data.operators[id];
		this.selectedOperator = undefined;

		this.api.Delete("simulator/"+this.queryParam.id+"/flow/operator/"+id).subscribe((res:any)=>{
			console.log("operator removido");
		}, (error:any)=>{
			console.error(error);
		})
	}

	deletarEtapa(id:number){
		this.flow.flowchart('deleteOperator', id);
	}

	duplicarEtapa(id:number){

		let d = this.flow.flowchart('getOperatorData', id);

		let description = window.prompt("Digite o nome da etapa:", d.properties.title);

		if(description!=null){

			this.api.Post("simulator/"+this.queryParam.id+"/flow/operator/"+id+"/duplicate", {
				description: description
			}).subscribe((res:any)=>{
				console.log(res);
				this.FlowUpdateOperator(res);
			}, (error:any)=>{
				console.error(error);
			})

		}

	}

	editarEtapa(id:number){
		switch (this.data.operators[id].properties.type) {
			case "protocol":
				this.novoProtocolo(id);
				break;
			
			default:
				this.novaEtapa(id);
				break;
		}
	}

	FlowUpdateOperator(data:any){

		this.data.operators[data.id] = {
			left: data.pos_x,
			top: data.pos_y,
			properties: {
				title: data.description,
				type: data.type,
				class: data.type,

				inputs: {
					input: {
						label: 'Entrada',
					}
				},
				outputs: {}
			}
		}

		for(let i:number=0; i<data.outputs.length; i++){
			this.data.operators[data.id].properties.outputs[i] = {
				label: data.outputs[i].name,
				type: data.outputs[i].type
			}
		}

		if(this.flow){
			this.flow.flowchart('setData', this.data);
		}

	}

	FlowUpdateLinks(data:any){
		let lnk = {
			fromOperator: data.from_operator,
			fromConnector: data.from_connector,
			toOperator: data.to_operator,
			toConnector: 'input',
			color: data.color
		}

		this.data.links[data.id] = lnk;

		delete this.data.links[0]; 

		if(this.flow){
			this.flow.flowchart('setData', this.data);
		}
	}


	CarregarFlow(){
		this.api.Get("simulator/"+this.queryParam.id+"/flow").subscribe((res:any)=>{
			
			for(let i:number=0; i<res.operators.length; i++){
				this.FlowUpdateOperator(res.operators[i]);
			}

			for(let i:number=0; i<res.links.length; i++){
				this.FlowUpdateLinks(res.links[i]);
			}

			if(res.position.start){
				this.data.operators[0].top = res.position.start.pos_y;
				this.data.operators[0].left = res.position.start.pos_x;
			}

			if(res.position.success){
				this.data.operators[88888].top = res.position.success.pos_y;
				this.data.operators[88888].left = res.position.success.pos_x;
			}

			if(res.position.fail){
				this.data.operators[99999].top = res.position.fail.pos_y;
				this.data.operators[99999].left = res.position.fail.pos_x;
			}

			this.exibirFlow();

		}, error=>{
			console.error("ERRO", error)
		});
	}

	CarregarParametros(){

		this.api.Get("simulator/parameters").subscribe((response:any[])=>{
			this.parametros = response;
		}, error=>{
			console.error("ERRO", error)
		});

	}

	CarregarAcoes(){

		this.api.Get("simulator/actions").subscribe((response:any[])=>{
			this.acoes = response;
		}, error=>{
			console.error("ERRO", error)
		});

	}

	RetornarParametro(id:number):any {

		for(let i:number=0; i<this.parametros.length; i++){
			if(this.parametros[i].id==id){
				return this.parametros[i];
			}
		}

		return undefined;

	}

	RetornarAcao(id:number):any {

		for(let i:number=0; i<this.acoes.length; i++){
			if(this.acoes[i].id==id){
				return this.acoes[i];
			}
		}

		return undefined;

	}

	FormataNumeroParametro(data:any[], param:any){
		if(param.format==null){
			return data.join(", ");
		} else {
			let v:string = param.format;
			for(let i=0; i<data.length; i++){
				v = v.replace("{"+i+"}", data[i]);
			}
			return v;
		}
	}


	RetornarOutputLiteral(data:any) {
		let act = this.RetornarAcao(data.id);
		let s = act.name;

		if(act.type=="medicine") {
			if(data.min_dose!=data.max_dose){
				s += " - "+data.min_dose+""+data.unit+" a "+data.max_dose+""+data.unit+" em "+data.method;
			} else {
				s += " - "+data.min_dose+""+data.unit+" em "+data.method;
			}
		} else if(data.sub_id!=undefined && data.sub_id>=0){
			s += " - "+act.values[data.sub_id].key;
		}

		return s;

	}


	RetornarParametroLiteral(data:any) {
		let param = this.RetornarParametro(data.id);

		let s = "<strong>"+param.name+": </strong>";

		switch (param.type) {
			case "boolean":
				s += data.value[0] ? "Sim" : "Não";
				break;
			case "number":
				if(data.variableValues){
					s += "entre "+this.FormataNumeroParametro(data.value, param)+" e "+this.FormataNumeroParametro(data.valueMax, param);
				} else {
					s += this.FormataNumeroParametro(data.value, param);
				}
				break;
			case "select":
				let vals:string[] = [];
				for(let di:number=0; di<data.value.length; di++){
					for(let i=0; i<param.values.length; i++){
						if(param.values[i].opt==data.value[di]){
							vals.push(param.values[i].opt);
						}
					}
				}

				s += vals.join(", ");
				break;
			
		}

		return s;
	}

	infoOperadorAtual:any;
	operatorLoad:any;
	CarregarOperador(id:number){
		this.operatorLoad = this.api.Get("simulator/"+this.queryParam.id+"/flow/operator/"+id).subscribe((res:any)=>{
			this.infoOperadorAtual = res;
		}, (error:any)=>{
			console.error(error);
		})
	}

	CancelarOperador(){
		if(this.operatorLoad){
			this.operatorLoad.unsubscribe();
			this.infoOperadorAtual = undefined;
		}
	}

}
