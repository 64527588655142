import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPhone'
})
export class FormatPhonePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (value != undefined){
      var tam = value.length;
      if(tam == 11){
          value = value.replace(/(\d\d)(\d\d\d\d\d)(\d\d\d\d)/, "($1) $2-$3");
          return value;
      }else{
          value = value.replace(/(\d\d)(\d\d\d\d)(\d\d\d\d)/, "($1) $2-$3");
          return value;
      }

    }else{
        return value;
    }

  }

}
