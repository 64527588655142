import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ApiRequestService } from "../../../services/api-request.service";

@Component({
  selector: "app-minhas-aulas-add-video",
  templateUrl: "./minhas-aulas-add-video.component.html",
  styleUrls: ["./minhas-aulas-add-video.component.scss"],
})
export class MinhasAulasAddVideoComponent {
  videos: any[] = [];
  selectedVideos: any[] = [];
  selectedVideo: any = undefined;
  page: number = 1;
  total: number = 0;
  carregando: boolean = false;
  adicionando: boolean = false;
  busca: string = "";
  requisicao: any;

  multiple: boolean = true;

  constructor(
    private api: ApiRequestService,
    public dialogRef: MatDialogRef<MinhasAulasAddVideoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.CarregarVideos();

    if (data.multiple !== undefined) {
      this.multiple = data.multiple;
    }
  }

  CarregarVideos() {
    this.carregando = true;

    let url = "video/vimeo?page=" + this.page;
    if (this.busca) {
      url += "&busca=" + this.busca;
    }

    this.requisicao = this.api.Get(url).subscribe(
      (res: any) => {
        this.carregando = false;
        this.total = res.total;
        this.videos = this.videos.concat(res.data);
      },
      (error) => {
        console.log(error);
        alert("Erro ao carregar os vídeos");
      }
    );
  }

  MaisVideos() {
    this.page++;
    this.CarregarVideos();
  }

  AddVideos() {
    let videos: any[] = [];

    this.adicionando = true;

    console.log(this.selectedVideos);

    for (let i: number = 0; i < this.selectedVideos.length; i++) {
      videos.push({
        uri: this.selectedVideos[i].uri,
        image: this.selectedVideos[i].pictures.sizes[2].link,
        title: this.selectedVideos[i].name,
        description: this.selectedVideos[i].description,
        total_time: this.selectedVideos[i].duration,
      });
    }

    this.api
      .Post("content/" + this.data.aula.id + "/video", { videos: videos })
      .subscribe(
        (res: any) => {
          console.log(res);
          this.dialogRef.close(res);
        },
        (error) => {
          this.adicionando = false;
          console.log(error);
          alert("Erro ao adicionar os vídeos");
        }
      );
  }

  UpdateVideo() {
    this.adicionando = true;

    const video = {
      id: this.data.params.id_video,
      uri: this.selectedVideos[0].uri,
      image: this.selectedVideos[0].pictures.sizes[2].link,
      title: this.selectedVideos[0].name,
      description: this.selectedVideos[0].description,
      total_time: this.selectedVideos[0].duration,
    };

    this.api.Put(`video/${this.data.params.id_video}`, video).subscribe(
      (res: any) => {
        this.dialogRef.close(res);
      },
      (error: any) => {
        this.adicionando = false;
        console.log(error);
        alert("Erro ao atualizar o video.");
      }
    );
  }

  SaveOrUpdateVideos() {
    if (this.multiple) {
      this.AddVideos();
    } else {
      this.UpdateVideo();
    }
  }

  SelectionChange({ option }) {
    
    if (this.multiple) {
      if(option._selected){
        if(!this.selectedVideos.some(x=>x.uri==option.value.uri))
          this.selectedVideos.push(option.value);
      }
    } else {
      this.selectedVideos = [option.value];
    }

  }

  LimparBusca() {
    this.busca = "";
    this.BuscarVideo();
  }

  BuscarVideo() {
    if (this.busca.length > 3 || this.busca.length == 0) {
      if (this.requisicao != null) {
        this.requisicao.unsubscribe();
      }

      this.videos = [];
      this.page = 1;
      this.CarregarVideos();
    }
  }

  Close(): void {
    this.dialogRef.close();
  }
}
