import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime, switchMap} from 'rxjs/operators';
import { Config } from "../../../utils/Config";
import { User } from '../../../utils/User';

import { ApiRequestService } from '../../../services/api-request.service';
import { ValidateEmailExist } from '../../../utils/ValidateEmailExist';

import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Validation } from '../../../utils/Validation';
import { environment } from '../../../../environments/environment';


@Component({
	selector: 'instructors-form',
	templateUrl: './instructors-form.component.html',
	styleUrls: ['./instructors-form.component.scss']
})
export class InstructorsFormComponent extends Validation implements OnInit {

	currentUser:User = new User();

	states:any[] = [];
	cities:any[] = [];
	params:any;
	parent:any;

	validateEmailExist:ValidateEmailExist = new ValidateEmailExist(this.api);

	InstructorForm:FormGroup = new FormGroup({
		photo: new FormControl(''),
		name: new FormControl('', Validators.required),
		email: new FormControl('', [Validators.required, Validators.email], this.validateEmailExist.CheckEmailTaken.bind(this.validateEmailExist)),
		info: new FormGroup({
			phone: new FormControl('', Validators.required),
			gender: new FormControl('', Validators.required),
			specialty: new FormControl('', Validators.required),
			state: new FormControl('', Validators.required),
			city: new FormControl('', Validators.required),
			lattes: new FormControl(''),
			description: new FormControl('', [Validators.minLength(250), Validators.maxLength(2000)]),
		})
	});

	config:any = Config;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private notifications: NotificationsService,
		private api:ApiRequestService
	) {
		super();
	}

	ngOnInit() {

		this.route.params.subscribe(ret => {
			this.params = ret;
			if(ret.id!=undefined){
				this.LoadData(ret.id);
			}
		});

		this.LoadStates();
	}


	LoadData(id){

		if(id=="me") id = this.currentUser.GetData().id;

		this.api.Get("../user/"+id).subscribe((res:any)=>{
			
			this.InstructorForm.removeControl("email")
			this.InstructorForm.addControl("email", new FormControl(''));

			this.InstructorForm.get("photo").setValue(res.photo);
			this.InstructorForm.get("name").setValue(res.name);
			this.InstructorForm.get("email").setValue(res.email);
			if(res.info){
				this.InstructorForm.get("info").get("phone").setValue(res.info.phone);
				this.InstructorForm.get("info").get("gender").setValue(res.info.gender);
				this.InstructorForm.get("info").get("specialty").setValue(res.info.specialty);
				this.InstructorForm.get("info").get("state").setValue(res.info.state);
				this.InstructorForm.get("info").get("city").setValue(res.info.city);
				this.InstructorForm.get("info").get("lattes").setValue(res.info.lattes);
				this.InstructorForm.get("info").get("description").setValue(res.info.description);
			}

			this.LoadCities();

		}, error=> {
			alert("Erro ao carregar dados. Contate o administrador");
			console.log(error.error);
		})
	}

	LoadStates(){

		this.api.Get("../estados").subscribe((response:any[])=>{
			this.states = response;
		}, error=>{
			console.error("ERRO", error)
		});

	}

	LoadCities(){

		this.cities = [];

		this.api.Get("../cidades/"+this.InstructorForm.get('info').get('state').value).subscribe((response:any[])=>{
			this.cities = response;
		}, error=>{
			console.error("ERRO", error)
		});

	}

	Save(){

		if(this.InstructorForm.valid){

			let url = "user/instructor";

			if(this.params.id) url = url+"/"+this.params.id;


			let data = this.InstructorForm.value;
			this.api.Post(url, data).subscribe((res:any)=>{
				this.notifications.create("Sucesso!", "Usuário salvo com sucesso", NotificationType.Success, { timeOut: 2500 } );
				//this.router.navigate(['/instructors']);
				if(this.parent) this.parent.SaveSuccess(res);

			}, ((res:any)=>{
				console.error(res);
				if(this.parent) this.parent.SaveFail();
			}))

		} else {
			if(this.parent) this.parent.SaveFail();
		}

	}

	SelectPhoto(field:any){
		field.click();
	}


	uploadingPhoto:boolean = false;
	ChangePhoto(event) {


		if(!this.currentUser.CheckLogin()) return;

		let f:File = event.target.files[0];

		if(!f.type.match('image.*')){
			alert("Apenas arquivos de imagem são permitidos para envio");
			return;
		}

		this.uploadingPhoto = true;
		this.api.UploadS3(f, "profile/").then((res:any)=>{
			let url = environment.awsS3.url+res.folder+"{size}/"+res.filename;
			this.InstructorForm.get("photo").setValue(url);
		})
	}

}
