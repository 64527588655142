import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ApiRequestService } from '../../../services/api-request.service';


@Component({
  selector: 'app-minhas-aulas-video-add-sumario',
  templateUrl: './minhas-aulas-video-add-sumario.component.html',
  styleUrls: ['./minhas-aulas-video-add-sumario.component.scss']
})
export class MinhasAulasVideoAddSumarioComponent {

	salvando:boolean = false;
	sumario:any = {
		time: "00:00",
		title: "",
	};

	constructor(
		private api:ApiRequestService,
		public dialogRef: MatDialogRef<MinhasAulasVideoAddSumarioComponent>,
		@Inject(MAT_DIALOG_DATA) public data:any
	) {

		let s;
		if(data.sumario){
			s = data.sumario.time;
			this.sumario.title = data.sumario.title;
			this.sumario.id = data.sumario.id;
		} else {
			s = Math.round(Math.max(0, data.seconds-5));
			
		}

		let date = new Date(null);
		date.setSeconds(s);
		let result = date.toISOString().substr(14, 5);

		this.sumario.time = result;


	}


	Close(): void {
		this.dialogRef.close();
	}

	Salvar(){
		this.salvando = true;

		let t = [this.sumario.time.substr(0,2), this.sumario.time.substr(2,2)];

		let data = {
			title: this.sumario.title,
			time: parseInt(t[0])*60+parseInt(t[1])
		}


		let url = this.sumario.id ? "video/"+this.data.video.id+"/summary/"+this.sumario.id : "video/"+this.data.video.id+"/summary";
		
		this.api.Post(url, {summary: data}).subscribe(
			(res:any) => {
				this.salvando = false;
				this.dialogRef.close(res);
			},
			error => {
				this.salvando = false;
				console.log(error);
				alert("Erro ao adicionar os vídeos");
			})
	}

}
