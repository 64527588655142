import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { User } from '../../utils/User';
import { ApiRequestService } from '../../services/api-request.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	currentUser:User = new User();
	dashboard:any;
	startDate = new Date("2020-03-01").toISOString().substr(0,10);
	endDate = new Date().toISOString().substr(0,10);

	constructor(
		private api:ApiRequestService,
		private router: Router,
	) { }

	ngOnInit() {

		if(this.currentUser.CheckPermissionArray(["master"])){
			this.setStartDate();
		} else if(this.currentUser.CheckPermissionArray(["instructor"])){
			this.router.navigate(["/minhas_aulas"]);
		} else {
			this.router.navigate(["/calendario"]);
		}

	}

	setStartDate(){
		this.loadDashboardData();
	}

	loadDashboardData(){
		this.api.Get("dashboard?startDate="+this.startDate+"&endDate="+this.endDate).subscribe(res=>this.dashboard=res);
	}

	filterDashboardData():any {
		return this.dashboard.filter(v=>v.plans.total>0);
	}

}
