import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from '../../../services/api-request.service';

@Component({
  selector: 'app-minhas-aulas-atividade',
  templateUrl: './minhas-aulas-atividade.component.html',
  styleUrls: ['./minhas-aulas-atividade.component.scss']
})
export class MinhasAulasAtividadeComponent implements OnInit {

	dados:any;
	lista:any[] = [];
	pag:number = 1;

	constructor(
		private api:ApiRequestService
  	) { }

	ngOnInit() {
		this.CarregarDados();
	}

	CarregarDados(){
		
		this.dados = undefined;

		this.api.Get("activity/content?page="+this.pag).subscribe((res:any)=>{
			this.lista = this.lista.concat(res.data);
			this.dados = res;
			console.log(res);

		}, error=> {
			alert("Erro ao carregar dados. Contate o administrador");
			console.log(error.error);
		})
	}

	CarregarMais(){
		this.pag++;
		this.CarregarDados();
	}

}
