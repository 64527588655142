import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd  } from '@angular/router';
import { ApiRequestService } from "../../../services/api-request.service";
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { filter } from 'rxjs/operators';
import { NotificationsService, NotificationType } from "angular2-notifications";

@Component({
  selector: 'app-clients-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, AfterViewInit  {

	search:string = "";
	filter:string[] = [];
	clients:any = {};
	columnsToDisplay = ["name", "active", "created_at", "acoes"];

	@ViewChild(MatSort) sort: MatSort;

	constructor(
		public api: ApiRequestService,
		private route: ActivatedRoute,
		private router: Router,
    private notifications: NotificationsService,
	) { }

	ngOnInit() {
		this.GetData();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.GetData();
    });
	}

	ngAfterViewInit() {
		this.sort.sortChange.subscribe(v=>{this.GetData(0)});
	}

	GetData(pag:number = 0, pageSize:number = 25) {

		let url = "client?page="+(pag+1)+"&pageSize="+pageSize;
		if(this.search) url += "&q="+this.search;

		this.api.Get(url).subscribe(res=>this.clients=res);
	}

	Search() {
		this.GetData(0, this.clients ? this.clients.per_page : 25);
	}

	ChangeFilter(){
		this.GetData(0, this.clients ? this.clients.per_page : 25);
	}

	public ChangePage(event?:PageEvent){
		console.log(event);
		this.GetData(event.pageIndex, event.pageSize);
	}

  downloadKey(id) {

    this.api.Get(`client/${id}/download-key`).subscribe(res=>{
      this.downloadBase64File(res['base64'], `${res['file']}.key`);
    });
  }

  downloadBase64File(base64: string, fileName: string) {

    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    link.click();

    URL.revokeObjectURL(link.href);
  }

  updateStatusClient(id) {

    this.api.Post(`client/${id}/update-status`, {}).subscribe(res=>{
      this.notifications.create(
        "Sucesso!",
        "Status alterado com sucesso!",
        NotificationType.Success,
        { timeOut: 5000 }
      );

      this.GetData();
    });
  }

}
