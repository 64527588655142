import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ApiRequestService } from '../../../services/api-request.service';


@Component({
  selector: 'app-minhas-aulas-topicos',
  templateUrl: './minhas-aulas-topicos.component.html',
  styleUrls: ['./minhas-aulas-topicos.component.scss']
})
export class MinhasAulasTopicosComponent implements OnInit {

	topicos:any[];
	topicosSelecionados:any[] = [];

	salvando:boolean = false;

	constructor(
		private api:ApiRequestService,
		public dialogRef: MatDialogRef<MinhasAulasTopicosComponent>,
		@Inject(MAT_DIALOG_DATA) public data:any
	) {
	}

	ngOnInit(){

		for(let i:number=0; i<this.data.aula.topics.length; i++){
			this.topicosSelecionados.push( this.data.aula.topics[i].id.toString() );
		}

		this.CarregarTopicos();

	}

	compareFn(t1, t2): boolean { 
		//return t1 && t2 ? t1.key === t2.key : t1 === t2;
		console.log(t1, t2);
		return true;
	}

	CarregarTopicos(){
		this.api.Get("topic?content_id="+this.data.aula.id).subscribe((res:any)=>{

			for(let i:number=0; i<res.length; i++){
				res[i].selected = this.topicosSelecionados.includes(res[i].id);
			}

			this.topicos = res;

		});
	}

	Salvar(){
		console.log(this.topicosSelecionados);
		let d:any = {
			topics: this.topicosSelecionados
		}

		this.salvando = true;

		this.api.Post("content/"+this.data.aula.id+"/topics", d).subscribe(
			(res:any) => {
				console.log(res);
				this.dialogRef.close(res);
			},
			error => {
				console.log(error);
				alert("Erro ao salvar tópicos");
			})

	}

	Close(): void {
		this.dialogRef.close();
	}

}
