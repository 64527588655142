import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ApiRequestService } from '../../../services/api-request.service';
import { Config } from '../../../utils/Config';


@Component({
  selector: 'app-minhas-aulas-add-arquivo',
  templateUrl: './minhas-aulas-add-arquivo.component.html',
  styleUrls: ['./minhas-aulas-add-arquivo.component.scss']
})
export class MinhasAulasAddArquivoComponent {

	config = Config;
	adicionando:boolean = false;
	arquivoSel:any = {
		name: ""
	};
	
	type:any;

	constructor(
		private api:ApiRequestService,
		public dialogRef: MatDialogRef<MinhasAulasAddArquivoComponent>,
		@Inject(MAT_DIALOG_DATA) public data:any
	) {
	}

	SelecionarArquivo(file:any){
		this.arquivoSel = file.target.files[0];
		console.log(this.arquivoSel);
	}

	Close(): void {
		this.dialogRef.close();
	}

	Enviar(){

		if(!this.type){
			alert("Selecione o tipo de arquivo");
			return;
		}

		this.adicionando = true;

		this.api.UploadS3(this.arquivoSel, "admin/files/").then((data)=>{
			//console.log(data);
			this.SalvarArquivo(data);
		});

	}

	SalvarArquivo(dataFile:any){

		let name = dataFile.data.Key.split("/");

		let data = {
			url: dataFile.data.Location,
			s3_obj: dataFile.data.Key,
			name: this.arquivoSel.name,
			type: this.type
		}

		this.api.Post("content/"+this.data.aula.id+"/file", data).subscribe(
			(res:any) => {
				//console.log(res);
				this.dialogRef.close(res);
			},
			error => {
				this.adicionando = false;
				console.log(error);
				alert("Erro ao adicionar os vídeos");
			})
	}

}
