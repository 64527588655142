import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-dialog-padrao',
  templateUrl: './dialog-padrao.component.html',
  styleUrls: ['./dialog-padrao.component.scss']
})
export class DialogPadraoComponent {

	constructor(
		public dialogRef: MatDialogRef<DialogPadraoComponent>,
		@Inject(MAT_DIALOG_DATA) public data:any
	) {
	}

	Close(): void {
		this.dialogRef.close();
	}

}
