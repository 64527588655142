import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { User } from "../../../utils/User";
import { StudioBlockFormComponent } from '../studio-block-form/studio-block-form.component';
import { EventEmitterService } from '../../../services/event-emitter.service';
import { ApiRequestService } from '../../../services/api-request.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Component({
  selector: 'app-studio-block-info-modal',
  templateUrl: './studio-block-info-modal.component.html',
  styleUrls: ['./studio-block-info-modal.component.scss']
})
export class StudioBlockInfoModalComponent {

	currentUser:User = new User();
	loadedData:any;

	constructor(
		private notifications: NotificationsService,
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<StudioBlockInfoModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data:any,
		private api:ApiRequestService
	) {

		let url:string = "";
		if(this.data.type=="block") {
			url = "studio/block/";
		} else if(this.data.type=="content") {
			url = "content/";
		}

		this.api.Get(url+data.id).subscribe(res=>{
			this.loadedData = res;
		})
	}

	Close(): void {
		this.dialogRef.close();
	}

	Edit() {
		this.Close();

		const dialogRef = this.dialog.open(StudioBlockFormComponent, {
			width: '800px',
			disableClose: true,
			data: {
				id: this.data.id
			}
		});

	}

	Delete() {

		if(!confirm("Tem certeza que deseja remover esta reserva?")) return;

		this.api.Delete("studio/block/"+this.data.id).subscribe(()=>{
			EventEmitterService.get("calendar.update").emit();
		});

		this.Close();

	}

	SendContractEmail(){

		let url = "studio/block/"+this.data.id+"/contract";

		if(this.loadedData.contract_key){
			url = url+"/email";
		}

		this.api.Post(url, "").subscribe(()=>{
			this.notifications.create("Sucesso!", "Contrato enviado com sucesso!", NotificationType.Success, { timeOut: 5000 } );
		});

	}



}
