import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from '../../../services/api-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { JourneyStepSearchComponent } from "../../../dialogs/journey-step-search/journey-step-search.component";


@Component({
  selector: 'app-journey-steps',
  templateUrl: './journey-steps.component.html',
  styleUrls: ['./journey-steps.component.scss']
})
export class JourneyStepsComponent implements OnInit {

	journey_id:number;
	journey:any;

	constructor(
		public dialog: MatDialog,
		private route: ActivatedRoute,
		private api:ApiRequestService,
		private _notifications: NotificationsService
	) { }

	ngOnInit() {
		this.route.params.subscribe(ret => {
			this.journey_id = ret.id;

			this.LoadJourneyData();
		});
	}

	dropItem(list:any, event: CdkDragDrop<any[]>) {
		moveItemInArray(list, event.previousIndex, event.currentIndex);
		this.SaveOrder();
	}

	SaveOrder(){
		let titles:any[] = [];
		let steps:any[] = [];

		let iTitle = 0;

		this.journey.titles.forEach(title=> {
			titles.push({
				id: title.id,
				order: iTitle
			})

			let iStep = 0;
			title.steps.forEach(step=> {
				steps.push({
					id: step.id,
					order: iStep
				})
				iStep++;
			})

			iTitle++;
		});

		this.api.Post("journey/"+this.journey_id+"/order", {titles: titles, steps: steps}).subscribe((res:any)=>{
			this._notifications.success("Sucesso!", "Ordem alterada com sucesso", { timeOut: 5000 });
		})
	}

	NewStep(){
		
		let name = prompt("Digite no nome da etapa");
		if(!name) return;

		this.api.Post("journey/"+this.journey_id+"/title", {name: name}).subscribe((res:any)=>{
			this._notifications.success("Sucesso!", "Título criado com sucesso", { timeOut: 5000 });
			this.LoadJourneyData();
		})
		
	}

	EditStep(data:any){

		let name = prompt("Digite no nome da etapa", data.name);
		if(!name) return;

		data.name = name;

		this.api.Post("journey/"+this.journey_id+"/title/"+data.id, {name: name}).subscribe((res:any)=>{
			this._notifications.success("Sucesso!", "Título editado com sucesso", { timeOut: 5000 });
		})

	}

	RemoveStep(data:any){

		if(!confirm("Tem certeza que deseja remover este título? Todas as etapas desse item serão removidos.")) return;

		this.api.Delete("journey/"+this.journey_id+"/title/"+data.id).subscribe((res:any)=>{
			this._notifications.success("Sucesso!", "Título removido com sucesso", { timeOut: 5000 });
			this.LoadJourneyData();
		})

	}

	LoadJourneyData() {
		this.api.Get("journey/"+this.journey_id).subscribe((res:any)=>{
			this.journey = res;
		})
	}

	OpenStepModal(titleData:any){

		const dialogRef = this.dialog.open(JourneyStepSearchComponent, {
			width: '600px',
			data: {
				types: 'contents,simulations,quizzes'
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if(result) {
				this.api.Post("journey/"+this.journey_id+"/title/"+titleData.id+"/step", result).subscribe((res:any)=>{
					this._notifications.success("Sucesso!", "Nova etapa adicionada com sucesso", { timeOut: 5000 });
					this.LoadJourneyData();
				})
			}
		});

	}

	RemoveStepItem(title:any, step:any){

		if(!confirm("Tem certeza que deseja remover este item?")) return;

		this.api.Delete("journey/"+this.journey_id+"/title/"+title.id+"/step/"+step.id).subscribe((res:any)=>{
			this._notifications.success("Sucesso!", "Item removido com sucesso", { timeOut: 5000 });
			this.LoadJourneyData();
		})

	}

}
