import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ApiRequestService } from "../../services/api-request.service";
import { NotificationsService, NotificationType } from "angular2-notifications";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatMenuTrigger,
} from "@angular/material";
import { Config } from "../../utils/Config";

import { MinhasAulasAddVideoComponent } from "./minhas-aulas-add-video/minhas-aulas-add-video.component";
import { MinhasAulasAddArquivoComponent } from "./minhas-aulas-add-arquivo/minhas-aulas-add-arquivo.component";

import { MinhasAulasTopicosComponent } from "./minhas-aulas-topicos/minhas-aulas-topicos.component";
import { MinhasAulasPlaylistsComponent } from "./minhas-aulas-playlists/minhas-aulas-playlists.component";

import { User } from "../../utils/User";
import { EventEmitterService } from "../../services/event-emitter.service";
import { environment } from "../../../environments/environment";
import * as moment from "moment";

@Component({
  selector: "app-minhas-aulas",
  templateUrl: "./minhas-aulas.component.html",
  styleUrls: ["./minhas-aulas.component.scss"],
})
export class MinhasAulasComponent implements OnInit {
  config = Config;
  pagAtual: number = 1;
  dados: any = {};
  aulas: any[] = [];
  usuario: User = new User();
  journey: any[] = [];
  painelAberto: boolean = false;
  carregando: boolean = false;
  refreshProductSubscription: any;

  filtros: any = {
    query: "",
    status: "",
    type: "",
    journey: "",
    show: "",
    topic: "",
    order: "CUSTOM",
    is_free: false,
    revision: false,
    text_revision: false,
    published: false,
    without_thumbnail: false,
  };

  topicos: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notifications: NotificationsService,
    public dialog: MatDialog,
    private api: ApiRequestService
  ) {}

  ngOnInit() {
    this.CarregarTopicos();
    this.CarregarJornadas();

    this.route.queryParams.subscribe((queryParams: any) => {
      this.pagAtual = 1;
      this.aulas = [];
      if (queryParams.query != undefined)
        this.filtros.query = queryParams.query;
      if (queryParams.status != undefined)
        this.filtros.status = queryParams.status;
      if (queryParams.type != undefined) this.filtros.type = queryParams.type;
      if (queryParams.journey != undefined)
        this.filtros.journey = queryParams.journey;
      if (queryParams.show != undefined) this.filtros.show = queryParams.show;
      if (queryParams.order != undefined)
        this.filtros.order = queryParams.order;
      if (queryParams.revision != undefined)
        this.filtros.revision = queryParams.revision;
      if (queryParams.is_free != undefined)
        this.filtros.is_free = queryParams.is_free;
      if (queryParams.without_thumbnail != undefined)
        this.filtros.without_thumbnail = queryParams.without_thumbnail;

      this.CarregarAulas();
    });

    this.refreshProductSubscription = EventEmitterService.get(
      "refreshProduct"
    ).subscribe((res) => {
      this.aulas = [];
      this.CarregarAulas();
    });

    this.router.events.subscribe((event) => {
      this.refreshProductSubscription.unsubscribe();
    });
  }

  Filtrar() {
    this.painelAberto = false;

    EventEmitterService.get("deleteAllRouteReuse").emit();

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.filtros,
      queryParamsHandling: "merge", // remove to replace all query params by provided
    });

    this.pagAtual = 1;
    this.aulas = [];
    this.CarregarAulas();
  }

  LimparFiltro() {
    this.filtros = {
      query: "",
      status: "",
      type: "",
      journey: "",
      show: "",
      order: "CUSTOM",
      is_free: false,
      revision: "",
      without_thumbnail: false,
    };

    this.pagAtual = 1;
    this.aulas = [];

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.filtros,
      queryParamsHandling: "merge", // remove to replace all query params by provided
    });
  }

  MaisAulas() {
    this.pagAtual++;
    this.CarregarAulas();
  }

  CarregarTopicos() {
    this.api.Get("topic").subscribe((res: any) => {
      this.topicos = res;
    });
  }

  CarregarJornadas() {
    this.api.Get("journey").subscribe((res: any) => {
      this.journey = res;
    });
  }

  CarregarAulas() {
    let url = `content?page=${this.pagAtual}`;

    if (this.filtros.query != "") {
      url += "&busca=" + this.filtros.query;
    }

    if (this.filtros.type != "") {
      url += "&type=" + this.filtros.type;
    }

    if (this.filtros.journey != "") {
      url += "&journey=" + this.filtros.journey;
    }

    if (this.filtros.status != "") {
      url += "&status=" + this.filtros.status;
    }

    if (this.filtros.show != "") {
      url += "&show=" + this.filtros.show;
    }

    if (this.filtros.order != "") {
      url += "&order=" + this.filtros.order;
    }

    if (this.filtros.topic != "") {
      url += "&topic=" + this.filtros.topic;
    }

    if (this.filtros.revision) {
      url += "&revision";
    }

    if (this.filtros.is_free) {
      url += "&is_free";
    }

    if (this.filtros.without_thumbnail) {
      url += "&without_thumbnail";
    }

    if (this.filtros.text_revision === '0') {
      url += "&revised=0"
    } else if (this.filtros.text_revision === '1') {
      url += "&revised=1"
    }

    if (this.filtros.published) {
      url += "&published=1"
    }

    this.carregando = true;

    this.api.Get(url).subscribe(
      (res: any) => {
        this.carregando = false;
        console.log(res);
        this.dados = res;
        this.aulas = this.aulas.concat(res.data);
      },
      (error) => {
        this.carregando = false;
        console.log(error);
        alert("Erro ao retornar conteúdos");
      }
    );
  }

  RemoverAulas(id: number) {
    if (!confirm("Tem certeza que deseja remover esta aula?")) {
      return;
    }

    this.api.Delete("content/" + id).subscribe(
      (res: any) => {
        this.notifications.create(
          "Sucesso!",
          "Registro removido com sucesso",
          NotificationType.Success,
          { timeOut: 5000 }
        );

        for (let i: number = 0; i < this.aulas.length; i++) {
          if (this.aulas[i].id == id) {
            this.aulas.splice(i, 1);
            return;
          }
        }
      },
      (error) => {
        alert("Erro ao carregar dados. Contate o administrador");
        console.log(error.error);
      }
    );
  }

  AlterarStatus(aula, status) {
    let data: any = {
      status: status,
    };

    if (status == "approved" && !this.AulaNosPadroes(aula)) {
      alert(
        "Aula fora dos padrões. Preencha todos os campos da aula antes de aprovar."
      );
      return;
    }

    if (status == "reproved") {
      let txt = prompt(
        "Digite o motivo da reprovação",
        aula.reproval_reason ? aula.reproval_reason : ""
      );
      if (txt == null) {
        return;
      } else {
        data.reproval_reason = txt;
      }
    }

    this.api.Post("content/" + aula.id, data).subscribe(
      (res: any) => {
        console.log(res);

        for (let i: number = 0; i < this.aulas.length; i++) {
          if (this.aulas[i].id == res.id) {
            this.aulas[i] = res;
            return;
          }
        }

        this.notifications.create(
          "Sucesso!",
          "Status alterado com sucesso!",
          NotificationType.Success,
          { timeOut: 5000 }
        );
      },
      (error) => {
        alert("Erro ao realizar ação. Contate o administrador");
        console.log(error.error);
      }
    );
  }

  AdicionarVideo(aula): void {
    const dialogRef = this.dialog.open(MinhasAulasAddVideoComponent, {
      width: "600px",
      data: { aula: aula },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res != undefined) {
        for (let i: number = 0; i < this.aulas.length; i++) {
          if (this.aulas[i].id == res.id) {
            this.aulas[i] = res;
            return;
          }
        }
      }
    });
  }

  EditarTopicos(aula): void {
    this.router.navigate(["/minhas_aulas/edit/" + aula.id]);
    return;

    const dialogRef = this.dialog.open(MinhasAulasTopicosComponent, {
      width: "600px",
      data: { aula: aula },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res != undefined) {
        this.AtualizarAula(res);
      }
    });
  }

  EditarPlaylists(aula): void {
    const dialogRef = this.dialog.open(MinhasAulasPlaylistsComponent, {
      width: "600px",
      data: { aula: aula },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res != undefined) {
        this.AtualizarAula(res);
      }
    });
  }

  DownloadFile(file) {
    window.open(file);
  }

  DeleteFile(file, aula) {
    this.api.DeleteS3(file.s3_obj).then((data: any) => {});

    this.api.Delete("content/" + aula.id + "/file/" + file.id).subscribe(
      (res: any) => {
        this.notifications.create(
          "Sucesso!",
          "Arquivo removido com sucesso",
          NotificationType.Success,
          { timeOut: 3000 }
        );

        this.AtualizarAula(res);
      },
      (error) => {
        alert("Erro. Contate o administrador");
        console.log(error.error);
      }
    );
  }

  EditFileType(file, aula, type) {
    let data = {
      type: type,
    };

    this.api.Post("content/" + aula.id + "/file/" + file.id, data).subscribe(
      (res: any) => {
        this.notifications.create(
          "Sucesso!",
          "Arquivo atualizado com sucesso",
          NotificationType.Success,
          { timeOut: 3000 }
        );

        this.AtualizarAula(res);
      },
      (error) => {
        alert("Erro. Contate o administrador");
        console.log(error.error);
      }
    );
  }

  EnviarArquivo(aula): void {
    const dialogRef = this.dialog.open(MinhasAulasAddArquivoComponent, {
      width: "600px",
      data: { aula: aula },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res != undefined) {
        this.AtualizarAula(res);
      }
    });
  }

  FormataAreas(aula: any) {
    let areas: string[] = [];

    if (parseInt(aula.medicine)) areas.push("Medicina");
    if (parseInt(aula.nursing)) areas.push("Enfermagem");
    if (parseInt(aula.nursing_tech)) areas.push("Técnico de Enfermagem");

    return areas.join(", ");
  }

  RetornarTipo(aula) {
    for (let i = 0; i < this.config.videoTypes.length; i++) {
      if (this.config.videoTypes[i].key == aula.type) {
        return this.config.videoTypes[i].value;
      }
    }
  }

  RetornarProdutos(aula) {
    let list = "";
    let i = 0;
    for (let produto of aula.products) {
      list += produto.name;
      i++;
      if (i != aula.products.length) {
        list += ", ";
      }
    }
    return list;
  }

  GetPyramid(data) {
    let ret = [];
    for (let i = 0; i < this.config.pyramid.length; i++) {
      if (data.pyramid.includes(this.config.pyramid[i].key)) {
        ret.push(this.config.pyramid[i].value);
      }
    }

    if (ret.length == 0) return "Nenhum item da pirâmide selecionado";

    return ret.join("; ");
  }

  GetTargetAudiences(data) {
    let ret = [];

    data.target_audiences.forEach((d: any) => {
      ret.push(d.name);
    });

    if (ret.length == 0) return "Nenhum público alvo selecionado";

    return ret.join("; ");
  }

  GetDeadlineDateDiff(data) {
    let end = moment(data.approval_deadline);
    let start = moment(data.approval_deadline).subtract(10, "days");

    var duration = moment.duration(end.diff(start));
    var left = moment.duration(end.diff(moment()));
    var hours = duration.asHours();
    var hoursLeft = left.asHours();

    return hoursLeft / hours;
  }

  DeadlinePassed(data) {
    let date = moment(data.approval_deadline);
    return date.isBefore(moment());
  }

  AtualizarAula(res: any) {
    if (res != undefined) {
      for (let i: number = 0; i < this.aulas.length; i++) {
        if (this.aulas[i].id == res.id) {
          this.aulas[i] = res;
          return;
        }
      }
    }
  }

  PublicarAula(aula: any) {
    /*if(aula.published && (aula.playlists.length==0 || aula.topics.length==0 || aula.short_description=="")){
			alert("Adicione pelo menos um tópico, uma playlist e descrição para essa aula.");
			aula.published = false;
			return;
		}*/

    this.api
      .Post("content/" + aula.id, { published: aula.published })
      .subscribe(
        (res: any) => {
          this.notifications.create(
            "Sucesso!",
            "Aula publicada com sucesso",
            NotificationType.Success,
            { timeOut: 3000 }
          );
        },
        (error: any) => {
          console.log(error);
          this.notifications.create(
            "Erro!",
            "Erro ao publicar a aula",
            NotificationType.Error,
            { timeOut: 3000 }
          );
        }
      );
  }

  AddTextRevision(aula: any) {
    this.api
      .Post("content/" + aula.id, { revised: aula.revised })
      .subscribe(
        (res: any) => {
          this.notifications.create(
            "Sucesso!",
            "Aula publicada com sucesso",
            NotificationType.Success,
            { timeOut: 3000 }
          );
        },
        (error: any) => {
          console.log(error);
          this.notifications.create(
            "Erro!",
            "Erro ao publicar a aula",
            NotificationType.Error,
            { timeOut: 3000 }
          );
        }
      );
  }

  CancelarAgendamento(aula: any) {
    if (!confirm("Tem certeza que deseja cancelar o agendamento dessa aula?")) {
      return;
    }

    let data = {
      status: "registered",
    };

    this.api.Post("content/" + aula.id, data).subscribe(
      (res: any) => {
        console.log(res);
        aula.status = "registered";
        this.notifications.create(
          "Sucesso!",
          "Agendamento cancelado com sucesso",
          NotificationType.Success,
          { timeOut: 3000 }
        );
      },
      (error: any) => {
        console.log(error);
        this.notifications.create(
          "Erro!",
          "Erro cancelar agendamento",
          NotificationType.Error,
          { timeOut: 3000 }
        );
      }
    );
  }

  AtualizarThumb(aula: any) {
    this.api
      .Post("content/" + aula.id + "/video/thumb/vimeo", {})
      .subscribe((res: any) => {
        for (let i: number = 0; i < this.aulas.length; i++) {
          if (this.aulas[i].id == res.id) {
            this.aulas[i].image = res.image;
            return;
          }
        }
      });
  }

  EnviarArquivoThumb(event, aula: any) {
    let f: File = event.target.files[0];

    if (!f.type.match("image.*")) {
      alert(
        "Arquivo inválido. Apenas arquivos de imagem são permitidos para envio"
      );
      return;
    }

    this.api.UploadS3(f, "public/thumbnail/").then((res: any) => {
      let url = environment.awsS3.url + res.folder + "{size}/" + res.filename;

      this.api
        .Post("content/" + aula.id, { image: url })
        .subscribe((res: any) => {
          aula.image = res.image;
        });
    });
  }

  ConvertToPlaylist(aula: any) {
    this.api
      .Post("content/" + aula.id + "/convertToPlaylist", {})
      .subscribe((res: any) => {
        this.notifications.create(
          "Sucesso!",
          "Convertido com sucesso",
          NotificationType.Success,
          { timeOut: 5000 }
        );
        this.CarregarAulas();
      });
  }

  AulaNosPadroes(aula: any) {
    return (
      aula.keywords &&
      aula.short_description &&
      aula.description
    );
  }
}
