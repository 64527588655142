import { Component, OnInit } from '@angular/core';
import { User } from '../../utils/User';
import { ApiRequestService } from "../../services/api-request.service";

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

	usuario:User = new User();
	faqs:any = {
		tutorial: [],
		simsave: [],
		instructor_pop: [],
		simsave_pop: []
	};

	grupos:string[] = ["tutorial","simsave"];
	iGrupo:number = 0;

	constructor(
		public api:ApiRequestService
	) { }

	ngOnInit() {

		if(this.usuario.CheckPermission("videomaker")){
			this.grupos.push("simsave_pop");
		}

		if(this.usuario.CheckPermissionArray(["instructor", "coordenator"])){
			this.grupos.push("instructor_pop");
		}

		this.CarregarFaq();

	}

	CarregarFaq(){

		this.api.Get("faq/"+this.grupos[this.iGrupo]).subscribe((res:any)=>{
			console.log(res);
			this.faqs[this.grupos[this.iGrupo]] = res;

			this.iGrupo++;
			if(this.iGrupo<this.grupos.length) this.CarregarFaq();

		})

	}

}
