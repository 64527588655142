import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ApiRequestService } from '../../../services/api-request.service';


@Component({
  selector: 'app-simulator-feedbacks-add-video',
  templateUrl: './simulator-feedbacks-add-video.component.html',
  styleUrls: ['./simulator-feedbacks-add-video.component.scss']
})
export class SimulatorFeedbacksAddVideoComponent {

	busca:string = "";
	videos:any[] = [];
	dataVideos:any;
	carregando:boolean = false;

	constructor(
		private api:ApiRequestService,
		public dialogRef: MatDialogRef<SimulatorFeedbacksAddVideoComponent>,
		@Inject(MAT_DIALOG_DATA) public data:any
	) {
		this.CarregarVideos();
	}

	requisicao:any;

	CarregarVideos(page?:number){
		this.carregando = true;

		let url = "content?order=CUSTOM";

		if(this.busca){
			url += "&busca="+this.busca;
		}

		if(page){
			url += "&page="+page;
		}

		this.requisicao = this.api.Get(url).subscribe(
			(res:any) => {
				this.carregando = false;
				this.dataVideos = res;
				this.videos = this.videos.concat(res.data);
			},
			error => {
				console.log(error);
				alert("Erro ao carregar os vídeos");
			})
	}

	MaisVideos(){
		console.log(this.dataVideos);
		this.CarregarVideos( this.dataVideos.current_page+1 );
	}


	LimparBusca(){
		this.busca = "";
		this.BuscarVideo();
	}

	BuscarVideo(){
		
		if(this.busca.length>3 || this.busca.length==0){

			if(this.requisicao!=null){
				this.requisicao.unsubscribe();
			}

			this.videos = [];
			this.CarregarVideos();

		}
	}

	AddVideo(conteudo:any, video:number, sumario?:any){

		let data:any = {
			content: {
				id: conteudo.id,
				title: conteudo.title
			}, 
			video: {
				i: video,
				id: conteudo.videos[video].id,
				title: conteudo.videos[video].title
			}
		};

		if(sumario){
			data.summary = {
				id: sumario.id,
				title: sumario.title,
				time: sumario.time
			};
		}

		this.dialogRef.close(data);
	}

	Close(): void {
		this.dialogRef.close();
	}

}
