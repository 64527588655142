import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from "../../../services/api-request.service";
import { PageEvent } from '@angular/material/paginator';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ActivatedRoute, Router } from '@angular/router';

import { Config } from "../../../utils/Config";
import { User } from '../../../utils/User';


@Component({
  selector: 'app-simulator-casos-listar',
  templateUrl: './simulator-casos-listar.component.html',
  styleUrls: ['./simulator-casos-listar.component.scss']
})
export class SimulatorCasosListarComponent implements OnInit {

	dados:any = undefined;

	columnsToDisplay = ["name", "acoes"];
	queryParams:any;

	constructor(
		private route: ActivatedRoute,
		private _notifications: NotificationsService,
		public apiRequest: ApiRequestService
	) { }

	ngOnInit() {
		this.route.params.subscribe(ret => {
			this.queryParams = ret;
			this.CarregarDados();
		});
	}

	pagAtual:number = 0;
	CarregarDados(pag:number = 0, pageSize:number = 25){

		if(this.dados!=undefined)
			this.dados.data = [];

		let url = "simulator?page="+(pag+1)+"&pageSize="+pageSize+"&type="+this.queryParams.type;

		this.apiRequest.Get(url).subscribe((ret:any)=>{
			this.dados = ret;
		});

		this.pagAtual = pag;

	}

	Duplicar(data){
		if( confirm("Tem certeza que deseja duplicar este item?") ){

			this.apiRequest.Post("simulator/"+data.id+"/duplicate", {}).subscribe((ret:any)=>{
				this._notifications.create("Sucesso!", "Registro duplicado com sucesso", NotificationType.Success, { timeOut: 5000 } );
				this.CarregarDados(this.pagAtual);
			});

		}
	}

	Excluir(data){
		if( confirm("Tem certeza que deseja remover este item?") ){

			this.apiRequest.Delete("simulator/"+data.id).subscribe((ret:any)=>{
				this._notifications.create("Sucesso!", "Registro removido com sucesso", NotificationType.Success, { timeOut: 5000 } );
				this.CarregarDados(this.pagAtual);
			});

		}
	}

	public AtualizarPaginacao(event?:PageEvent){
		console.log(event);
		this.CarregarDados(event.pageIndex, event.pageSize);
	}

}
