import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiRequestService } from "../../../services/api-request.service";
import { User } from '../../../utils/User';
import { Config } from "../../../utils/Config";
import { NotificationsService, NotificationType } from 'angular2-notifications';


@Component({
  selector: 'app-usuarios-permissoes',
  templateUrl: './usuarios-permissoes.component.html',
  styleUrls: ['./usuarios-permissoes.component.scss']
})
export class UsuariosPermissoesComponent implements OnInit {

	dados:any;
	config = Config;
	usuario:User = new User();

	constructor(
		private route: ActivatedRoute,
		private _notifications: NotificationsService,
		public apiRequest: ApiRequestService
	) {
		console.log(this.usuario);
	}

	ngOnInit() {
		this.route.params.subscribe(params => {
			this.CarregarDados( params['id'] );
		});

	}

	CarregarDados(id){


		this.apiRequest.Get("user/"+id).subscribe((ret:any)=>{
			console.log(ret);
			this.dados = ret;
		});

	}

	PermissoesDisponiveis(){
		let perm = [];

		for(let i=0; i<this.config.roles.length; i++){
			if(this.usuario.CheckPermissionArray(this.config.roles[i].require)){
				perm.push( this.config.roles[i] );
			}
		}

		return perm;
	}

	ChecarPermissao(permission:string){
		return this.dados.roles.indexOf(permission)>=0;
	}

	AlterarPermissao(permissions){
		let novasPermissoes = [];
		let opts = permissions.options._results;

		for(let i=0; i<opts.length; i++){
			novasPermissoes.push({
				role: opts[i].value,
				enabled: opts[i].selected
			});
		}

		this.apiRequest.Post("user/"+this.dados.id+"/permission", {roles: novasPermissoes}).subscribe((ret:any)=>{
				this._notifications.create("Sucesso!", "Permissão alterada com sucesso", NotificationType.Success, { timeOut: 2500 } );
		}, (error)=>{
			this._notifications.create("Erro!", "Erro ao alterar a permissão do usuário, tente novamente mais tarde", NotificationType.Error, { timeOut: 5000 } );
		});

	}


}
