import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ApiRequestService } from '../../services/api-request.service';
import { InstructorsFormDialogComponent } from '../../pages/instructors/instructors-form-dialog/instructors-form-dialog.component';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent {

	users:any = {
		apiSubscribe: null,
		loading: false,
		data: null,
		list: []
	};
	searchText:string = "";

	constructor(
		private api:ApiRequestService,
		public dialogRef: MatDialogRef<UserSearchComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData:any,
		public dialog: MatDialog
	) {
		this.LoadUsers();
	}

	LoadUsers(page:number = 1, pagesize:number = 25){

		this.users.loading = true;

		if( this.users.apiSubscribe ) this.users.apiSubscribe.unsubscribe();

		let url = "user?page="+page;

		url += "&q="+this.searchText;
		if(this.dialogData && this.dialogData.role) url += "&role="+this.dialogData.role;

		this.users.apiSubscribe = this.api.Get(url).subscribe((res:any)=>{
			this.users.loading = false;
			this.users.data = res;
			this.users.list = this.users.list.concat(res.data);
		})

	}

	Search(){
		if(this.searchText.length>=3){
			this.users.list = [];
			this.LoadUsers();
		}
	}

	AddUser(){
		const dialogRef = this.dialog.open(InstructorsFormDialogComponent, {
			width: '800px'
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log(result);
			this.SelectUser(result);
		});
	}

	SelectUser(user:any){
		this.dialogRef.close(user);
	}

	Close(){
		this.dialogRef.close();
	}

}
