import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'topic-thumb',
  templateUrl: './topic-thumb.component.html',
  styleUrls: ['./topic-thumb.component.scss']
})
export class TopicThumbComponent implements OnInit {

	@Input() data:any;
	@Input() parent:any;

	constructor() { }

	ngOnInit() {
	}

	

}
