import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ApiRequestService } from '../../../services/api-request.service';


@Component({
  selector: 'app-minhas-aulas-playlists',
  templateUrl: './minhas-aulas-playlists.component.html',
  styleUrls: ['./minhas-aulas-playlists.component.scss']
})
export class MinhasAulasPlaylistsComponent implements OnInit {

	playlists:any[];
	playlistsSelecionados:any[] = [];

	salvando:boolean = false;

	constructor(
		private api:ApiRequestService,
		public dialogRef: MatDialogRef<MinhasAulasPlaylistsComponent>,
		@Inject(MAT_DIALOG_DATA) public data:any
	) {
	}

	ngOnInit(){

		for(let i:number=0; i<this.data.aula.playlists.length; i++){
			this.playlistsSelecionados.push( this.data.aula.playlists[i].id.toString() );
		}

		console.log(this.playlistsSelecionados);

		this.CarregarPlaylists();

	}

	compareFn(t1, t2): boolean { 
		//return t1 && t2 ? t1.key === t2.key : t1 === t2;
		console.log(t1, t2);
		return true;
	}

	CarregarPlaylists(){
		this.api.Get("playlist?content_id="+this.data.aula.id).subscribe((res:any)=>{
			console.log(res);
			for(let i:number=0; i<res.length; i++){
				res[i].selected = this.playlistsSelecionados.includes(res[i].id);
			}

			this.playlists = res;

		});
	}

	Salvar(){
		console.log(this.playlistsSelecionados);

		let detach:any[] = [];
		for(let i:number=0; i<this.playlists.length; i++){
			if(this.playlistsSelecionados.indexOf(this.playlists[i].id.toString())<0){
				detach.push(this.playlists[i].id);
			}
		}

		let d:any = {
			playlists: this.playlistsSelecionados,
			detach: detach
		}
		console.log(d);

		this.salvando = true;

		this.api.Post("content/"+this.data.aula.id+"/playlists", d).subscribe(
			(res:any) => {
				console.log(res);
				this.dialogRef.close(res);
			},
			error => {
				console.log(error);
				alert("Erro ao salvar tópicos");
			})

	}

	Close(): void {
		this.dialogRef.close();
	}

}
