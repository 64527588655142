import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith, debounceTime, switchMap} from 'rxjs/operators';
import { Config } from "../../../utils/Config";
import { User } from '../../../utils/User';

import { ApiRequestService } from '../../../services/api-request.service';
import { ValidateEmailExist } from '../../../utils/ValidateEmailExist';

import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Component({
  selector: 'app-clients-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

	usuario:User = new User();

	cadastro:any = {
		info: {}
	};

	estados:any[] = [];
	cidades:any[] = [];
	validateEmailExist:ValidateEmailExist = new ValidateEmailExist(this.api);

	enviandoForm:boolean = false;
	editando:boolean = false;

	formCadastro:FormGroup= new FormGroup({
		name: new FormControl('', Validators.required),
		active: new FormControl('', Validators.required)
	});

	config:any = Config;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private notifications: NotificationsService,
		private api:ApiRequestService
	) {
	}

	ngOnInit() {

		this.route.params.subscribe(ret => {
			if(ret.id!=undefined){
				this.CarregarDados(ret.id);
			}
		});
	}

	dados;
	CarregarDados(id){

		this.editando = true;

		this.formCadastro = new FormGroup({
			name: new FormControl('', Validators.required),
			active: new FormControl('', Validators.required)
		});

		this.api.Get("../user/"+id).subscribe((res:any)=>{
			this.dados = res;
			console.log(this.dados);

			// if(res.info){

			// 	let dataFormatada;
			// 	if(res.info.birthdate){
			// 		let data = res.info.birthdate.split("-");
			// 		dataFormatada = data[2]+"/"+data[1]+"/"+data[0];
			// 	} else {
			// 		dataFormatada = "";
			// 	}

			// 	this.formCadastro.setValue({
			// 		name: res.name,
			// 		active: res.active
			// 	});
			// } else {
			// 	this.formCadastro.get("name").setValue(res.name);
			// 	this.formCadastro.get("email").setValue(res.email);
			// }

		}, error=> {
			alert("Erro ao carregar dados. Contate o administrador");
			console.log(error.error);
		})
	}

	GetField(field:string, form:FormGroup = this.formCadastro){
		return form.get(field);
	}

	Cadastrar(){

		if(this.formCadastro.valid){
			let v = this.formCadastro.value;

			let data = {
				name: v.name,
				active: v.active
      }

			let url = !this.dados ? "../admin/client" : "../admin/client/"+this.dados.id;

			this.enviandoForm = true;
			this.api.Post(url, data).subscribe((client: any)=>{
        this.enviandoForm = false;
        this.router.navigate(['clients/list'])
			}, (error:any)=>{
				this.enviandoForm = false;

				this.notifications.create("Erro!", "Erro ao cadastrar o cliente. Tente novamente mais tarde.", NotificationType.Error, { timeOut: 5000 } );
				console.error(error.error);

			});
		}
	}


	SalvoSucesso(){
		this.enviandoForm = false;
		this.notifications.create("Sucesso!", "Usuário salvo com sucesso", NotificationType.Success, { timeOut: 2500 } );

		this.Voltar();

	}

	Voltar(): void {
		this.router.navigate(['usuarios']);
	}

}
