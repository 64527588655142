import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ApiRequestService } from '../../../services/api-request.service';

import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { User } from '../../../utils/User';

@Component({
	selector: 'app-minhas-aulas-agendamento',
	templateUrl: './minhas-aulas-agendamento.component.html',
	styleUrls: ['./minhas-aulas-agendamento.component.scss']
})
export class MinhasAulasAgendamentoComponent implements OnInit {

	dados:any;

	formCadastro:FormGroup= new FormGroup({
		date: new FormControl(new Date(), Validators.required),
		start_time: new FormControl("", Validators.required),
		end_time: new FormControl("", Validators.required),
	});

	horas:string[] = [];
	usuario:User = new User();

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private notifications: NotificationsService,
		private api:ApiRequestService
	) {

		let ini:number = 9;
		let end:number = 20;

		console.log(this.usuario);
		if(this.usuario.CheckPermissionArray(['videomaker', 'secretary'])){
			ini = 4;
			end = 23;
		}

		for(let i:number = ini; i<=end; i++){
			
			let h:string = i.toString();
			if(i<10) h = "0"+h;

			this.horas.push(h+":00");
			this.horas.push(h+":30");
		}
	}

	ngOnInit() {

		this.route.params.subscribe(ret => {
			if(ret.id!=undefined){
				this.CarregarDados(ret.id);
			}
		});
	}

	GetField(field:string, form:FormGroup = this.formCadastro){
		return form.get(field);
	}

	GetError(field:string, form:FormGroup = this.formCadastro){
		if(form.get(field).hasError('required')){
			return "Este campo é obrigatório";
		} else {
			return "Preencha este campo corretamente";
		}
	}

	CarregarDados(id:number){

		this.api.Get("content/"+id).subscribe((res:any)=>{
			this.dados = res;

			let v = {
				date: new Date(this.dados.scheduled_start_date),
				start_time: this.dados.scheduled_start_date.substr(11,5),
				end_time: this.dados.scheduled_end_date.substr(11,5)
			}

			console.log(v);

			this.formCadastro.setValue(v);

			/*
			this.formCadastro.setValue({
				scheduled_date: res.scheduled_date,
			});*/

		}, error=> {
			alert("Erro ao carregar dados. Contate o administrador");
			console.log(error.error);
		})
	}

	Salvar(){
		if(this.formCadastro.valid){

			let v = this.formCadastro.value;

			if( parseInt(v.start_time.replace(":", ""))>=parseInt(v.end_time.replace(":", "")) ){
				alert("A hora inicial não pode ser maior que a hora final");
				return;
			}

			let data = {
				status: "scheduled",
				scheduled_start_date: v.date.getFullYear()+"-"+(v.date.getMonth()+1)+"-"+v.date.getDate()+" "+v.start_time,
				scheduled_end_date: v.date.getFullYear()+"-"+(v.date.getMonth()+1)+"-"+v.date.getDate()+" "+v.end_time
			}

			console.log(data);

			this.api.Post("content/"+this.dados.id, data).subscribe(res=>{
				console.log(res);

				this.notifications.create("Sucesso!", "Gravação da aula agendada com sucesso!", NotificationType.Success, { timeOut: 5000 } );
				this.router.navigate(["/minhas_aulas"])

			}, error=> {

				this.notifications.create("Erro!", error.error.error, NotificationType.Error, { timeOut: 5000 } );

				//alert("Erro ao salvar aula. Contate o administrador");
				console.log(error.error);
			})

		}
	}

}
