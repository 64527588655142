import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from '../../../services/api-request.service';
import { Validation } from '../../../utils/Validation';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { User } from "../../../utils/User";

@Component({
	selector: 'app-registration-code',
	templateUrl: './registration-code.component.html',
	styleUrls: ['./registration-code.component.scss']
})
export class RegistrationCodeComponent extends Validation implements OnInit {

	currentUser: User = new User();

  formCadastro: FormGroup = new FormGroup({
    product_id: new FormControl(null, [Validators.required]),
		count: new FormControl(1, [Validators.required, Validators.min(1)]),
		expiration_time: new FormControl(24, Validators.required),
		type: new FormControl("free_days", Validators.required),
		discount: new FormControl(0),
		duration: new FormControl(1, Validators.required),
		period: new FormControl("D", Validators.required),
		require_credit_card: new FormControl(true, Validators.required),
		allow_limit: new FormControl(false, Validators.required),
		use_limit: new FormControl(1, [Validators.required, Validators.min(1)]),
		set_code: new FormControl(false),
		code: new FormControl(null),
	});

	generatedCodes:string[];
  numbers: number[] = [];
  products: Array<any> = [];

	constructor(
		private api:ApiRequestService
	) {
		super();

		for(let i:number=0; i<=31; i++) {
			this.numbers.push(i+1);
		}
	}

  ngOnInit() {

    this.api.Get("products").subscribe((res:any[])=>{
      this.products = res;
		});

	}

	changeType(){
		if(this.formCadastro.get('type').value=="free_days"){
			this.formCadastro.get('discount').setValidators([])
		} else {
			this.formCadastro.get('discount').setValidators([Validators.required, Validators.min(1), Validators.max(100)])
		}
	}

	sendingForm:boolean = false;
	GenerateCodes(){
    if (!this.formCadastro.valid) return;

		this.sendingForm = true;
		this.api.Post("registrationCode", this.formCadastro.value).subscribe((res:any[])=>{
			this.sendingForm = false;
			this.generatedCodes = res;
		});
	}

}
