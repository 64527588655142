import { AbstractControl } from '@angular/forms';
import { ApiRequestService } from '../services/api-request.service';

export class ValidateEmailExist {

	constructor(private api:ApiRequestService){
	}

	CheckEmailTaken(control: AbstractControl) {

		return new Promise(resolve => {

			this.api.Get("../user/checkEmail?email="+control.value).subscribe((response:any)=>{
					if(response.exist){
						resolve({ 'emailTaken': true });
					} else {
						resolve(null);
					}
				}, error=>{
					resolve(null);
				})
			
		});

		//return null;
	}

}