import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ApiRequestService } from "../../services/api-request.service";
import { User } from '../../utils/User';


@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent {

	usuario:User = new User();
	logando:boolean = false;

	formLogin:FormGroup= new FormGroup({
		email: new FormControl('', [Validators.required, Validators.email]),
		password: new FormControl('', [Validators.required, Validators.minLength(6)]),
		remember: new FormControl(''),
		
	});

	constructor(
		private location: Location,
		public apiRequest: ApiRequestService
	) {

	}

	GetField(field:string, form:FormGroup = this.formLogin){
		return form.get(field);
	}

	GetError(field:string, form:FormGroup = this.formLogin){
		if(form.get(field).hasError('required')){
			return "Este campo é obrigatório";
		} else if(form.get(field).hasError('email')){
			return "E-mail inválido";
		} else if(form.get(field).hasError('minlength')){
			return "A senha precisa ter no mínimo 6 caracteres";
		} else if(form.get(field).hasError('pwdMatch')){
			return "As senhas não coincidem";
		} else if(form.get(field).hasError('emailTaken')){
			return "Este e-mail já está cadastrado no SimSave. ";
		} else {
			return "Preencha este campo corretamente";
		}
	}

	Login(){

		if(!this.formLogin.valid){
			return;
		}

		this.logando = true;

		let data = {
			email: this.formLogin.get("email").value,
			password: this.formLogin.get("password").value,
			remember: this.formLogin.get("remember").value
		}

		this.apiRequest.Post("../login", data)
		.subscribe(
			(res:any) => {
				this.logando = false;
				this.usuario.data.token = res.token;
				this.usuario.Save();

				this.GetUserData();
			},
			error => {
				this.logando = false;
				console.log(error);
				alert("Usuário e/ou senha inválidos");
			}
		)

	}

	GetUserData(){

		this.logando = true;

		this.apiRequest.Get("../user/me").subscribe(

			(res:any) => {

				this.usuario.data.user = res;

				if(this.usuario.CheckPermissions()){

					this.usuario.Save();
					location.replace("");

				} else {
					alert("Você não tem permissão para acessar essa área");
					this.usuario.Logout();
				}


				this.logando = false;

			},
			error => {
				console.log(error);
				alert("Erro ao retornar dados do usuário");

				this.logando = false;


			}

		)
	}

}
