import { Component, OnInit, ViewChild } from '@angular/core';
import { StudioBlockFormComponent } from '../studio/studio-block-form/studio-block-form.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CalendarioComponent } from '../../component/calendario/calendario.component';
import { EventEmitterService } from '../../services/event-emitter.service';
import { User } from '../../utils/User';

@Component({
	selector: 'app-calendario-exibir',
	templateUrl: './calendario-exibir.component.html',
	styleUrls: ['./calendario-exibir.component.scss']
})
export class CalendarioExibirComponent implements OnInit {

	currentUser:User = new User();

	constructor(public dialog: MatDialog) { }

	@ViewChild('calendar') calendar:CalendarioComponent;

	ngOnInit() {}

	AddStudioBlock(): void {
		const dialogRef = this.dialog.open(StudioBlockFormComponent, {
			width: '800px',
			disableClose: true
		});

		dialogRef.afterClosed().subscribe(result => {
			this.calendar.Update();
		});
	}

}
