import { Component, OnInit, ViewChild } from '@angular/core';
import { InstructorsFormComponent } from '../instructors-form/instructors-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitterService } from '../../../services/event-emitter.service';

@Component({
  selector: 'app-instructors-form-page',
  templateUrl: './instructors-form-page.component.html',
  styleUrls: ['./instructors-form-page.component.scss']
})
export class InstructorsFormPageComponent implements OnInit {

	@ViewChild('form') form:InstructorsFormComponent;
	sending:boolean = false;

	constructor(
		private router: Router
	) { }

	ngOnInit() {
		this.form.parent = this;
	}

	Save() {
		this.sending = true;
		this.form.Save();
	}

	SaveSuccess(data:any) {
		EventEmitterService.get("deleteAllRouteReuse").emit();
		this.router.navigate(['/instructors']);
		this.sending = false;
		console.log(data);
	}

	SaveFail() {
		this.sending = false;
	}

	Back(): void {
		this.router.navigate(['/instructors']);
	}

}
