import { Component, OnInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import {Observable} from 'rxjs';
import { map, startWith, debounceTime, switchMap } from 'rxjs/operators';
import { Config } from "../../../utils/Config";
import { ApiRequestService } from '../../../services/api-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { EventEmitterService } from '../../../services/event-emitter.service';
import { User } from '../../../utils/User';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchStudioBlockComponent } from '../../../dialogs/search-studio-block/search-studio-block.component';
import { UserSearchComponent } from '../../../dialogs/user-search/user-search.component';
import { MatListModule, MatListOption, MatSelectionList } from '@angular/material/list';
import { MatCheckbox } from '@angular/material/checkbox';

export interface Instructor {
	id: number;
	name: string;
}


@Component({
  selector: 'app-minhas-aulas-form',
  templateUrl: './minhas-aulas-form.component.html',
  styleUrls: ['./minhas-aulas-form.component.scss']
})
export class MinhasAulasFormComponent implements OnInit {

    isLinear = false;
    firstFormGroup: FormGroup = new FormGroup({
      title: new FormControl('', Validators.required),
      hidden: new FormControl(false, Validators.required),
      is_free: new FormControl(false, Validators.required),
      instructor: new FormControl(''),
      type: new FormControl('', Validators.required),
      short_description: new FormControl(''),
      description: new FormControl(''),
      medicine: new FormControl(false),
      nursing: new FormControl(false),
      nursing_tech: new FormControl(false),
      targetAudience: new FormControl([]), //[Validators.required, Validators.minLength(1), Validators.maxLength(2)]),
      pyramid: new FormControl([]), //, [Validators.minLength(1), Validators.maxLength(2)]),
      keywords: new FormControl("", [Validators.required]),
    });

    secondFormGroup: FormGroup = new FormGroup({
      products: new FormControl([null], [Validators.required, Validators.minLength(1)]),
    });

    thirdFormGroup: FormGroup = new FormGroup({
      topics: new FormControl([]),
    });

    categoriesFormGroup: FormGroup = new FormGroup({
      categories: new FormControl([]),
    });

	@ViewChild(MatSelectionList) productsList: MatSelectionList;
	@ViewChildren('topicsList') topicsList!: QueryList<MatSelectionList>;
	@ViewChildren('categoriesList') categoriesList!: QueryList<MatSelectionList>;
	@ViewChildren('topicCheck') topicsCheckList!: QueryList<MatCheckbox>;

	selectedInstructor: Instructor = undefined;
	filteredInstructors: Observable<Instructor[]>;
	instructors: Instructor[] = [];
	topics:any;
	categories:any;
	targetAudiences:any;
	config:any = Config;
	products: any[];
	studio_block:any;
	dados:any;

	secondFormComplete:boolean = false;

	currentUser:User = new User();
	
	constructor(
		public dialog: MatDialog,
		private route: ActivatedRoute,
		private router: Router,
		private notifications: NotificationsService,
		private api:ApiRequestService,
    	private _formBuilder: FormBuilder
	) {

		this.filteredInstructors = this.firstFormGroup.get('instructor').valueChanges
		.pipe(
			startWith(''),
			map(instructor => {
				this.GetInstructors();
				return this.instructors;
			})
		);

		if( this.currentUser.CheckPermission("videomaker") ){

			this.thirdFormGroup.removeControl("topics");
			this.firstFormGroup.removeControl("targetAudience");
			this.firstFormGroup.removeControl("pyramid");
			this.firstFormGroup.removeControl("keywords");
			this.firstFormGroup.addControl("targetAudience", new FormControl(''));
			this.firstFormGroup.addControl("pyramid", new FormControl(''));
			this.firstFormGroup.addControl("keywords", new FormControl(''));

		}
	}


	ngOnInit() {

		this.route.params.subscribe(ret => {
			if(ret.id != undefined){
			  this.isLinear = true;
				this.CarregarDados(ret.id);
			}
			else{
				this.isLinear = true;
      		}
		});

		this.route.queryParams.subscribe(ret => {
			if(ret.studio_block_id){
				this.api.Get("studio/block/"+ret.studio_block_id).subscribe(ret=>{
					this.studio_block = ret;
				});
			}
		})

		//this.LoadTargetAudiences();
	}


	CarregarDados(id:number){

		this.api.Get("content/"+id).subscribe((res:any)=>{

			this.dados = res;
			this.selectedInstructor = res.instructor;
			let topics = [];

			for(let i:number = 0; i < res.topics.length; i++)
				topics.push(res.topics[i].id);

			let targetAudiences = [];
			for(let i:number = 0; i < res.target_audiences.length; i++)
				targetAudiences.push(res.target_audiences[i].id);

			
			this.firstFormGroup.setValue({
				title: res.title,
				hidden: res.hidden,
				is_free: res.is_free,
				type: res.type,
				short_description: res.short_description,
				description: res.description,
				instructor: res.instructor.name,
				medicine: parseInt(res.medicine)==1,
				nursing: parseInt(res.nursing)==1,
				nursing_tech: parseInt(res.nursing_tech)==1,
				targetAudience: targetAudiences,
				pyramid: res.pyramid,
				keywords: res.keywords
			});

			setTimeout(() => {
				this.secondFormGroup.setValue({
					products: res.products
				});
			},10);

			if(res.products.length > 0) {
				setTimeout(() => {
					this.secondFormGroup.controls['products'].setErrors(null);
				},20);
			}

			setTimeout(() => {
				this.thirdFormGroup.setValue({
					topics: res.topics
				});
			},30);

			setTimeout(() => {
				this.LoadProducts(res.instructor_id);
			},40);

			this.studio_block = res.studio_block;


		}, error=> {
			alert("Erro ao carregar dados. Contate o administrador");
			console.log(error.error);
		});
	}

	LoadProducts(id:number){

		this.api.Get("crud/select?table=products&field=name").subscribe((res:any)=>{
			this.products = res;

			for(let prod of this.dados.products) {
				this.products.find((element:any) => element.id === prod.id).selected = true;
			}

			setTimeout(()=>{
				this.LoadTopics();
				this.LoadCategories();
				this.ProductsValidation();
			}, 1000);

		});

	}

	LoadTopics() {

		let ids:number[] = [];

		this.productsList.options.forEach((item: MatListOption) => {
			if(item.selected) ids.push(item.value.id);
		});

		if(ids.length > 0){
			this.api.Get("product/"+ids.join(",")+"/topics").subscribe((res:any)=>{
				this.topics = res;
			});
		}

	}

	LoadCategories() {

		let ids:number[] = [];

		this.productsList.options.forEach((item: MatListOption) => {
			if(item.selected) ids.push(item.value.id);
		});

		if(ids.length > 0){
			this.api.Get("product/"+ids.join(",")+"/categories").subscribe((res:any)=>{
				this.categories = res;
			});
		}

	}


	LoadTargetAudiences(){
		this.api.Get("crud/config_targetAudience?page=1&pageSize=25").subscribe((res:any)=>{
			this.targetAudiences = res.data;
		})
	}

	GetInstructors(){
		// this.api.Get("user/instructors?name="+this.formCadastro.get('instructor').value).subscribe((res:any)=>{
		// 	this.instructors = res.data;
		// })
	}

	SelectStudioBlock(){
		const dialogRef = this.dialog.open(SearchStudioBlockComponent, {
			width: '600px'
		});

		dialogRef.afterClosed().subscribe(result => {
			if(result){
				this.studio_block = result;
			}
		});
	}

	GetFirstField(field:string, form:FormGroup = this.firstFormGroup){
		return form.get(field);
	}
  GetSecondField(field:string, form:FormGroup = this.secondFormGroup){
    return form.get(field);
  }

  GetThridField(field:string, form:FormGroup = this.thirdFormGroup){
    return form.get(field);
  }


  GetError(field:string, form:FormGroup = this.firstFormGroup){
		if(form.get(field).hasError('email')){
			return "E-mail inválido";
		} else if(form.get(field).hasError('minlength')){
			return "Selecione no mínimo 1 item";
		} else if(form.get(field).hasError('maxlength')){
			return "Selecione no máximo 2 itens";
		} else if(form.get(field).hasError('pwdMatch')){
			return "As senhas não coincidem";
		} else if(form.get(field).hasError('emailTaken')){
			return "Este e-mail já está cadastrado no SimSave. ";
		} else if(form.get(field).hasError('required')){
			return "Este campo é obrigatório";
		} else {
			return "Preencha este campo corretamente";
		}
	}

	InstructorSel(opt:Instructor){
		this.selectedInstructor = opt;
	}

	SelectUser(){

		const dialogRef = this.dialog.open(UserSearchComponent, {
			width: '450px',
		});

		dialogRef.afterClosed().subscribe(result => {
			this.selectedInstructor = result;

			this.LoadProducts(result.id);

		});

	}

	Salvar(){

		this.firstFormGroup.get('description').markAsTouched();

		if(this.firstFormGroup.valid){

			if(this.selectedInstructor==undefined){
				alert("Selecione um professor antes de salvar a aula");
				return;
			}

			let v = this.firstFormGroup.value;
			v.instructor_id = this.selectedInstructor.id;

			v.pyramid = v.pyramid.toString();

			v.products = [];
			this.secondFormGroup.get("products").value.forEach(p => {
				v.products.push(p.id);
			})

			v.topics = [];
			this.topicsList.forEach((child: MatSelectionList) => {

				let i:number = 0;
				child.options.forEach((c: MatListOption) => {
					i++;
					if(c.selected) v.topics.push(c.value.id);
				});
			});


			this.topicsCheckList.forEach( (p:MatCheckbox)=> {
				if(p.checked) v.topics.push((p.value as any).id);
			})



			v.categories = [];
			this.categoriesList.forEach((child: MatSelectionList) => {
				child.options.forEach((c: MatListOption) => {
					if(c.selected) v.categories.push(c.value.id);
				});
			});

			console.log(v.categories);

	    	if(this.studio_block) v.studio_block_id = this.studio_block.id;

			let url = !this.dados ? "content" : "content/"+this.dados.id;

			this.api.Post(url, v).subscribe(res=>{
				console.log(console.log(v));

				this.notifications.create("Sucesso!", "Registro salvo com sucesso", NotificationType.Success, { timeOut: 5000 } );
				EventEmitterService.get("deleteAllRouteReuse").emit();
				this.router.navigate(["/minhas_aulas"])

			}, error=> {
				alert("Erro ao salvar aula. Contate o administrador");
				console.log(error.error);
			})

		}

	}

	ProductsValidation() {

		// retorna verdadeiro se for edição
		if(this.dados) {
			this.secondFormComplete = true;
			return;
		}

		let valid = true;
		let productSelection = this.products;
		let topics = this.topics;

		let selectedData:any = [];

		this.productsList.options.forEach((item: MatListOption) => {
			if(item.selected || this.CompareProducts(item.value)) { selectedData.push(item.value.id); }
		});


		for(let k in selectedData) {
			if(productSelection.filter((item) => { return item.readonly && item.id === selectedData[k] }).length > 0) {
					
				if(this.topics && topics.find((item) => item.id === selectedData[k]) != undefined) {
					topics.find((item) => item.id === selectedData[k]).readonly = true;
				}
			}
		}

		this.topics = topics;

		if(selectedData.length == 0) {
			valid = false;
		}

		this.secondFormComplete = valid;
	}

	ProductsChanged(event) {
		this.ProductsValidation();
	}

	StepChanged(event) {
		this.ProductsValidation();
	}

	Voltar(): void {
		this.router.navigate(['minhas_aulas']);
	}

	CompareProducts(obj1:any) {
		return (this.dados && this.dados.products) ? this.dados.products.find((element:any) => element.id === obj1.id ) != null : false;
	}

	CompareTopics(obj1:any) {
		return (this.dados && this.dados.topics && this.topics) ? this.dados.topics.find((element:any) => element.id === obj1.id) != null : false;
	}

	CompareCategories(obj1:any) {
		return (this.dados && this.dados.categories && this.categories) ? this.dados.categories.find((element:any) => element.id === obj1.id) != null : false;
	}

	CheckTopicSel(t) {
		if(!this.dados || !t) return false;
		return this.dados.topics.some(v=>v.id==t.id);
	}

}
