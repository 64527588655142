import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ApiRequestService } from '../../../services/api-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Validation } from '../../../utils/Validation';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';


@Component({
  selector: 'app-simulator-casos-fluxograma-protocolo-modal',
  templateUrl: './simulator-casos-fluxograma-protocolo-modal.component.html',
  styleUrls: ['./simulator-casos-fluxograma-protocolo-modal.component.scss']
})
export class SimulatorCasosFluxogramaProtocoloModalComponent extends Validation implements OnInit {

	formCadastro:FormGroup= new FormGroup({
		id: new FormControl('', Validators.required)
	});

	protocolos:any[] = [];

	constructor(
		public dialogRef: MatDialogRef<SimulatorCasosFluxogramaProtocoloModalComponent>,
    	@Inject(MAT_DIALOG_DATA) public dataDialog:any,
		private route: ActivatedRoute,
		private router: Router,
		private notifications: NotificationsService,
		private api:ApiRequestService
	) {
		super();
	}

	ngOnInit() {
		this.CarregarProtocolos();

		if(this.dataDialog.id_etapa){
			this.CarregarDados();
		}
	}

	CarregarDados(){
		this.api.Get("simulator/"+this.dataDialog.id_caso+"/flow/operator/"+this.dataDialog.id_etapa).subscribe((res:any)=>{
			console.log(res);
			this.formCadastro.get("id").setValue(res.parameters[0].id);
		}, error=>{
			console.error("ERRO", error)
		});
	}


	CarregarProtocolos(){

		this.api.Get("simulator?pageSize=999&type=protocol").subscribe((response:any)=>{
			this.protocolos = response.data;
		}, error=>{
			console.error("ERRO", error)
		});

	}

	RetornarProtocolo(id:number):any {

		for(let i:number=0; i<this.protocolos.length; i++){
			if(this.protocolos[i].id==id){
				return this.protocolos[i];
			}
		}

		return undefined;

	}

	salvando = false;
	Salvar(){

		if(!this.salvando && this.formCadastro.valid){

			let data = {
				type: "protocol",
				description: this.RetornarProtocolo(this.formCadastro.get("id").value).name,
				parameters: JSON.stringify([{
					id: this.formCadastro.get("id").value
				}]),
				outputs: JSON.stringify([
					{
						"name": "Sucesso",
						"type": "correct"
					},
					{
						"name": "Falha",
						"type": "wrong"
					}
				])
			}

			console.log(data);

			let url = "simulator/"+this.dataDialog.id_caso+"/flow/operator";

			if(this.dataDialog.id_etapa){
				url = url+"/"+this.dataDialog.id_etapa;
			}

			this.api.Post(url, data).subscribe((res:any)=>{
				this.salvando = false;
				console.log(res);
				this.dialogRef.close(res);
			}, (error:any)=>{
				this.salvando = false;
				console.error(error);
			})
		}

	}

	Fechar(){
		this.dialogRef.close();
	}

}
