import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from "../../../services/api-request.service";
import { PageEvent } from '@angular/material/paginator';
import { NotificationsService, NotificationType } from 'angular2-notifications';

import { Config } from "../../../utils/Config";
import { User } from '../../../utils/User';

@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.scss']
})
export class QuestionListComponent implements OnInit {

	painelAberto:boolean = false;
	usuario:User = new User();
	config = Config;

	tabela:any = [];
	dados:any;

	filtros:any = {
		query: "",
		role: "",
		coordenated: false
	};

	columnsToDisplay = ["question", "acoes"];

	constructor(
		private _notifications: NotificationsService,
		public apiRequest: ApiRequestService
	) { }

	ngOnInit() {
		this.CarregarDados();
	}

	pagAtual:number = 1;
	CarregarDados(pag:number = 1, pageSize:number = 25){

		if(this.dados!=undefined)
			this.dados.data = [];

		let url = "question?page="+(pag)+"&pageSize="+pageSize;

		if(this.filtros.query!=""){
			url += "&q="+this.filtros.query;
		}

		this.apiRequest.Get(url).subscribe((ret:any)=>{
			this.dados = ret;
		});

		this.pagAtual = pag;

	}

	public AtualizarPaginacao(event?:PageEvent){
		this.CarregarDados(event.pageIndex+1, event.pageSize);
	}

	public AjustaPermissoes(roles){
		let ret:any[] = [];

		for(let i:number=0; i<roles.length; i++){
			for(let j:number=0; j<Config.roles.length; j++){
				if(roles[i].role==Config.roles[j].key){
					ret.push(Config.roles[j]);
				}
			}
		}

		return ret;
	}

	Filtrar(){
		this.pagAtual = 1;
		this.CarregarDados();
	}

	LimparFiltro() {

		this.filtros = {
			query: "",
			role: "",
			coordenated: false
		};

		this.Filtrar();
	}

	Deletar(data:any){

		if(!confirm("Tem certeza que deseja remover esta questão?")){
			return;
		}

		this.apiRequest.Delete("question/"+data.id).subscribe(res=>{
			console.log(res);
			this.CarregarDados(this.dados.current_page, this.dados.per_page);
		});
	}

}
