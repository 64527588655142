import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ApiRequestService } from '../../../services/api-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { CleanTextForURLPipe } from '../../../pipes/clean-text-for-url.pipe';
import { Validation } from '../../../utils/Validation';


@Component({
  selector: 'app-simulator-anamnesis-form',
  templateUrl: './simulator-anamnesis-form.component.html',
  styleUrls: ['./simulator-anamnesis-form.component.scss']
})
export class SimulatorAnamnesisFormComponent extends Validation implements OnInit {

	formCadastro:FormGroup = new FormGroup({
		name: new FormControl('', Validators.required),
		multiple: new FormControl(false, Validators.required),
		min_options: new FormControl(0),
		max_options: new FormControl(0),
	});
	enviandoForm = false;

	questions:any[] = [];
	options:any[] = [];

	queryParams:any;
	dados:any = {};

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private notifications: NotificationsService,
		private api:ApiRequestService,
		private cleanText:CleanTextForURLPipe
	) {
		super();
	}

	ngOnInit() {

		this.route.params.subscribe(ret => {
			if(ret.id!=undefined){
				this.queryParams = ret;
				this.dados.id = ret.id;
				this.CarregarDados(ret.id);
			}
		});
	}


	CarregarDados(id:any){

		this.api.Get("simulator/anamnesis/"+id).subscribe((response:any)=>{

			this.formCadastro.get("name").setValue(response.name);
			this.formCadastro.get("multiple").setValue(response.multiple);
			this.formCadastro.get("min_options").setValue(response.min_options);
			this.formCadastro.get("max_options").setValue(response.max_options);
			
			this.questions = (response.questions);
			this.options = (response.options);
			console.log(this.dados);
		}, error=>{
			console.error("ERRO", error)
		});

	}

	AddOpt(objeto, data){
		objeto.push(data);
	}

	RemoveOpt(objeto, index){
		objeto.splice(index, 1);
	}

	Cadastrar(){



		if(!this.formCadastro.valid){
			return;
		}

		let data = JSON.parse(JSON.stringify(this.formCadastro.value));

		data.questions = JSON.stringify(this.questions);
		data.options = JSON.stringify(this.options);
		
		console.log(data);

		this.enviandoForm = true;

		
		let url = "simulator/anamnesis";
		if(this.dados.id){
			url = url+"/"+this.dados.id;
		}
		
		this.api.Post(url, data).subscribe((response:any)=>{
			this.notifications.create("Sucesso!", "Caso salvo com sucesso!", NotificationType.Success, { timeOut: 5000 } );
			this.router.navigate(['/simulador/anamnesis'])
		}, (error:any)=>{
			this.enviandoForm = false;

			this.notifications.create("Erro!", "Erro ao cadastrar. Tente novamente mais tarde.", NotificationType.Error, { timeOut: 5000 } );
			console.error(error.error);

		});
		
	}

}
