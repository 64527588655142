import { Router, RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from "@angular/router";
import { EventEmitterService } from '../services/event-emitter.service';

export class CustomRouteReuseStategy implements RouteReuseStrategy {

	handlers: { [key: string]: DetachedRouteHandle } = {};
	scrollPosition: { [key: string]: number } = {};

	constructor(){

		EventEmitterService.get("deleteRouteReuse").subscribe((res)=>{
			this.remove(res.name);
		});

		EventEmitterService.get("deleteAllRouteReuse").subscribe((res)=>{
			this.removeAll();
		});

		EventEmitterService.get("saveScrollPosition").subscribe((res)=>{
			this.saveScrollPosition(res.router, res.scrollTop);
		});

	}

	remove(name:string) {
		delete this.handlers[name];
	}

	removeAll() {
		this.handlers = {};
	}

	saveScrollPosition(router: Router, scrollTop:number){
		this.scrollPosition[router.url.substr(1)] = scrollTop;
	}

	shouldDetach(route: ActivatedRouteSnapshot): boolean {
		return route.data.shouldReuse || false;
	}

	store(route: ActivatedRouteSnapshot, handle: {}): void {
		if (route.data.shouldReuse) {
			this.handlers[route.routeConfig.path] = handle;
		}
	}

	shouldAttach(route: ActivatedRouteSnapshot): boolean {
		return !!route.routeConfig && !!this.handlers[route.routeConfig.path];
	}

	retrieve(route: ActivatedRouteSnapshot): {} {
		if (!route.routeConfig) return null;
		
		EventEmitterService.get("setScrollPosition").emit(this.scrollPosition[route.routeConfig.path]);
		return this.handlers[route.routeConfig.path];
	}

	shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
		return future.data.shouldReuse || false;
	}


}