import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';

export class Validation {

	constructor(){
	}

	GetField(field:string, form:FormGroup, subfield?:string):AbstractControl{
		if(subfield){
			return form.get(subfield).get(field);
		} else {
			return form.get(field);
		}
	}

	GetError(field:string, form:FormGroup, subfield?:string, additional?:any):string{

		let _field:AbstractControl = this.GetField(field, form, subfield);

		if(_field.hasError('required')){
			return "Este campo é obrigatório";
		} else if(_field.hasError('email')){
			return "E-mail inválido";
		} else if(_field.hasError('minlength')){
			return "Este campo precisa ter no mínimo "+additional.minLength+" caracteres";
		} else if(_field.hasError('maxlength')){
			return "O tamanho máximo deste campo é de "+additional.maxLength+" caracteres";
		} else if(_field.hasError('pwdMatch')){
			return "As senhas não coincidem";
		} else if(_field.hasError('emailTaken')){
			return "Este e-mail já está cadastrado no SimSave. ";
		} else {
			return "Preencha este campo corretamente";
		}
	}

}