export class UserData {
	id:number;
	name:string;
	email:string;
	roles:string[];
}

export class TokenInfo {

	token:string;
	user:UserData;

}

export class User {

	data:TokenInfo = new TokenInfo();

	constructor(){
		this.Load();		
	}

	GetData():UserData {
		return this.data.user;
	}

	CheckLogin(){
		return this.data.token!=null && this.data.token!="";
	}

	CompareId(id:number):boolean{
		
		return (this.CheckLogin() && this.GetData().id==id);
	}

	CheckPermission(permission:string){
		return this.data.user.roles != undefined ? this.data.user.roles.indexOf(permission)>=0 : false;
	}

	CheckPermissionArray(permission:string[]){

		if(permission.length==0){
			return true;
		}

		let ret = false;


		for(let i=0; i<permission.length; i++){
			if(this.CheckPermission(permission[i])){
				ret = true;
			}
		}

		return ret;
	}

	CheckPermissions(){

		let res:boolean = false;
		let permissions = ["admin", "instructor"];

		for(let i=0; i<permissions.length; i++){
			if(this.data.user.roles.indexOf(permissions[i])>=0){
				res = true;
			}
		}

		return res;

	}

	Save(){
		localStorage.setItem("userSession", JSON.stringify(this.data));
	}

	Load(){
		if(localStorage.getItem('userSession') !== null){
			this.data = JSON.parse(localStorage.getItem('userSession'));
			//console.log("POSSUI userSession", this.data);
		} else {
			//console.log("NÃO POSSUI userSession");
		}
	}

	Logout(){
		this.data = new TokenInfo();
		localStorage.removeItem("userSession");
	}

}