import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';
import { ApiRequestService } from "../../../services/api-request.service";
import { PageEvent } from '@angular/material/paginator';
import { ExcelServicesService } from '../../../services/excel-services.service'; 

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent implements OnInit {

	table:any;
	data:any;
	columnsToDisplay:string[] = [];

	constructor(
		public apiRequest: ApiRequestService,
		private route: ActivatedRoute,
		private router: Router,
		private excelService:ExcelServicesService
	) { }

	ngOnInit() {

		this.route.params.subscribe((p:any)=>{
			this.table = p.table;
			this.LoadReport();
		});

	}

	LoadReport(pag:number = 0, pageSize:number = 25){

		this.apiRequest.Get("report/"+this.table+"?page="+(pag+1)+"&pagesize="+pageSize).subscribe((res:any)=>{

			this.data = res;

			if(this.data.total>0){
				this.columnsToDisplay = Object.keys(this.data.data[0]);
			}

		});

	}

	public ChangePage(event?:PageEvent){
		this.LoadReport(event.pageIndex, event.pageSize);
	}

	exportAsXLSX():void {
		this.apiRequest.Get("report/"+this.table+"?page=1&pagesize=999999").subscribe((res:any)=>{
			this.excelService.exportAsExcelFile(res.data, this.table);  
		});
    }

}
