import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'topicsFilterPipe'
})
export class TopicsFilterPipePipe implements PipeTransform {

	transform(items: any[], filter?: string): any {
		
        console.log(filter);
		if (!items || !filter) {
            return items;
        }

        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter(item => filter.includes(item.type) );

	}

}
